import { writable } from 'svelte/store';
import LogRocket from 'logrocket';

import { setError } from './error.store';

import {
  createTransaction,
  getTransactionData,
  getTransactionFromSignedUrl,
  postDomain,
} from '../api';

import { getFrameData } from '../utils/locationUtils';
import { isInIframe } from '../utils/iframeUtils';
import { KEYS, saveToLocalStorage } from '../utils/localstorageUtils';

const transaction = writable({ name: '', surname: '', id: '', amount: '', backgroundUrl: '' });

async function startTransaction(id, token) {
  let transactionDetails = null;
  let nonce = null;

  if (id && token) {
    try {
      transactionDetails = await getTransactionData(id, token);
    } catch (e) {
      setError(e);
      return;
    }
  } else {
    const frameData = getFrameData();
    nonce = frameData.nonce;

    try {
      if (frameData.signature && frameData.data && frameData.merchantId) {
        transactionDetails = await getTransactionFromSignedUrl(frameData);
      } else {
        transactionDetails = await createTransaction(frameData);
      }
    } catch (e) {
      setError(e);
      return;
    }
  }

  if (!transactionDetails) {
    setError('InternalError');
    return;
  }

  try {
    await postDomain(transactionDetails.merchantId, {
      referrer: document.referrer,
      ancestorOrigins: document.location.ancestorOrigins,
      nonce,
      isIframe: isInIframe(),
      email: transactionDetails.email,
      name: transactionDetails.name,
      surname: transactionDetails.surname,
    });
  } catch {
    // skip - this is temporary
  }

  saveToLocalStorage(KEYS.SYSTEM_ID, transactionDetails.systemId);
  transaction.update((prevTransaction) => ({
    ...prevTransaction,
    ...transactionDetails,
  }));
  LogRocket.identify(transactionDetails.id);
}

function setTransactionDetails(details) {
  transaction.update((prevTransaction) => ({
    ...prevTransaction,
    ...details,
  }));
}

export { transaction, startTransaction, setTransactionDetails };
export default transaction;
