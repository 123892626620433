<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import api from '../../api';
  import {
    general as generalStrings,
    aeon as aeonStrings,
    verificationText,
  } from '../../resources/strings';
  import {
    emailSetInitialValues,
    otpSetInitialValues,
    emailSetShape,
    otpSetShape,
  } from './validation';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';
  import { state } from '../../stores/state.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';
  import OtpInstructions from './OtpInstructions.svelte';

  const setType = $state.customQuestion;

  onMount(() => timer.setNewTimer(2 * 60 - 5)); // 1:55

  const dispatch = createEventDispatcher();
  const EMAIL_SET = 'EmailCode';
  const initialValues = setType === EMAIL_SET ? emailSetInitialValues : otpSetInitialValues;
  const validationSchema = setType === EMAIL_SET ? emailSetShape : otpSetShape;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues,
    validationSchema,
    onSubmit: (submittedForm) =>
      api
        .postCode(
          $transaction.id,
          'set',
          JSON.stringify({
            otp: submittedForm.otp,
            emailCode: submittedForm.emailCode,
            transactionPassword: submittedForm.transactionPassword,
          }),
        )
        .then(() => {
          timer.resetTimer();
          dispatch('verificationSubmit');
        }),
  });
</script>

<form class="container" on:submit={handleSubmit}>
  {#if setType === 'EmailCode'}
    <Input
      label={verificationText.emailCode.title}
      name="emailCode"
      focus
      id="emailCode"
      type="text"
      bind:value={$form.emailCode}
      error={$errors.emailCode}
      touched={$touched.emailCode}
      {handleChange}
    />
  {:else}
    <Input
      label={verificationText.otp.title}
      name="otp"
      focus
      id="otpCode"
      type="text"
      bind:value={$form.otp}
      error={$errors.otp}
      touched={$touched.otp}
      {handleChange}
    />
    <OtpInstructions />
  {/if}
  <Input
    label={aeonStrings.transactionPassword}
    focus
    id="transactionPassword"
    name="transactionPassword"
    type="text"
    bind:value={$form.transactionPassword}
    error={$errors.transactionPassword}
    touched={$touched.transactionPassword}
    {handleChange}
  />

  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .error {
    color: red;
  }
</style>
