<script>
  import LogRocket from 'logrocket';

  import { generalError } from '../stores/error.store';
  import { resetSelectedBank } from '../stores/selected-bank.store';
  import { state } from '../stores/state.store';

  import Card from '../components/Card.svelte';
  import Timer from '../components/Timer.svelte';
  import SelectedBank from './SelectedBank.svelte';

  import { errors } from '../resources/strings';

  function handleTimeout() {
    if ($state.transactionResult === '' && $generalError === '') {
      $generalError = errors.timeout;
      LogRocket.track('Timeout');
    }
  }
</script>

<Card>
  <Timer on:timeout={handleTimeout} />
  <SelectedBank on:back={() => resetSelectedBank()} />
</Card>

<style>
</style>
