import { readable } from 'svelte/store';
import { createTransaction, postDomain } from '../api';
import { getFrameData } from '../utils/locationUtils';
import { isInIframe } from '../utils/iframeUtils';
import { setError } from './error.store';
import LogRocket from 'logrocket';
import { KEYS, saveToLocalStorage } from '../utils/localstorageUtils';

export const transaction = readable({}, async function start(set) {
  const frameData = getFrameData();
  set({ name: frameData.name, surname: frameData.surname, id: '', amount: '', backgroundUrl: '' }); // initial values

  try {
    await postDomain(frameData.merchant_id, {
      referrer: document.referrer,
      ancestorOrigins: document.location.ancestorOrigins,
      isIframe: isInIframe(),
      nonce: frameData.nonce,
      email: frameData.email,
      name: frameData.name,
      surname: frameData.surname,
    });
  } catch {
    // skip - this is temporary
  }

  try {
    const transactionDetails = await createTransaction(frameData);
    saveToLocalStorage(KEYS.SYSTEM_ID, transactionDetails.systemId);
    set(transactionDetails);
    LogRocket.identify(transactionDetails.id);
  } catch (e) {
    setError(e);
  }

  return function stop() {};
});
