<script>
  import LogRocket from 'logrocket';

  import BankCheckout from './containers/BankCheckout.svelte';
  import BankList from './containers/BankList.svelte';
  import InfoCard from './containers/InfoCard.svelte';
  import Status from './containers/Status/Status.svelte';
  import SupportText from './components/SupportText.svelte';
  import Result from './containers/Result.svelte';
  import { generalError, globalError } from './stores/error.store';
  import { selectedBank } from './stores/selected-bank.store';
  import { startTransaction, transaction } from './stores/transaction.store';
  import { state } from './stores/state.store';
  import { general as generalStrings } from './resources/strings';
  import { isResult } from './utils/stateUtils';
  import { onMount } from 'svelte';
  import { manualTransaction } from './stores/manual-transaction.store';
  import { setBanks } from './stores/banks.store';
  import Loading from './components/Loading.svelte';

  const { version } = APP_BUILD;

  if (APH_ENV === 'prod') {
    LogRocket.init('7agfsb/aphtest', {
      release: version,
      dom: {
        inputSanitizer: true,
        baseHref: 'https://test.t-paytech.com/', // PROD env is restricted to japan only
      },
      shouldCaptureIP: false,
      network: {
        requestSanitizer: (request) => {
          const tempCurr = request?.body?.state?.current;
          request.url = null;
          request.headers = null;
          request.body = {};
          if (tempCurr != null) {
            request.body.state = {};
            request.body.state.current = tempCurr;
          }
          return request;
        },
      },
      browser: {
        urlSanitizer: () => {
          return '';
        },
      },
    });
  }

  onMount(async () => {
    LogRocket.track('Start Session');

    if (window.location.hash) {
      await startTransaction();
      await setBanks();
    } else {
      window.addEventListener(
        'message',
        async (event) => {
          if (event.data.id && event.data.token) {
            await startTransaction(event.data.id, event.data.token);
            await setBanks();
          }
        },
        { once: true },
      );
    }
  });

  const postEvent = (status) => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          source: 'BTB PAYIN',
          status,
        },
        '*',
      );
    }, 500);
  };

  $: if ($state.transactionResult || $manualTransaction.status) {
    postEvent('success');
    LogRocket.track('Success');
  }

  $: if ($generalError) {
    postEvent('error');
    LogRocket.track('General Error', { message: $generalError });
  }
</script>

<div
  class="wrapper merchant-bg"
  style={$transaction.backgroundUrl ? `background-image: url('${$transaction.backgroundUrl}')` : ''}
>
  <div id="main-container" class="container">
    {#if !$transaction.id}
      <Loading />
    {:else}
      {#if $transaction.id !== '' && $generalError === ''}
        <InfoCard />
        {#if $selectedBank.name === ''}
          <SupportText text={generalStrings.selectBank} />
        {/if}
      {/if}
      {#if $generalError}
        <Status type="error" message={$generalError} />
      {:else if $globalError}
        <Status type="global" globalError={$globalError} />
      {:else if isResult($state.current)}
        <Result />
      {:else if $selectedBank.name === ''}
        <BankList />
      {:else}
        <BankCheckout />
      {/if}
    {/if}
  </div>
</div>

<style>
  :global(:root) {
    --gray-interface: #f3f6fa;
    --gray-light: #f7f7f7;
    --gray-text-light: #a0a0a0;
    --gray-text: #646464;
    --gray-dark: #333;
    --green: #789f35;
    --green-light: #94c247;
    --blue: #325ea5;
    --blue-light: #a4c1fe;
    --white: #fff;
    --red-dark: #b71c1c;
    --blue-main: #66c3f1;
    --blue-main-light: #aee1f9;
    --blue-main-darker: #50b3ea;
    --blue-main-dark: #47a4d6;
    --text-main-light: #f5f8fa;
    --main-button-height: 58px;
  }

  :global(body) {
    width: 100%;
    background: #f7f7f7;
    font-family: 'Zen Kaku Gothic New', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', Osaka,
      'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  }
  :global(*) {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .wrapper {
    padding: 1rem clamp(2px, 1%, 1rem);
    padding-bottom: var(--main-button-height);
    min-height: 100vh;
  }

  .container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    max-width: 600px;
    padding-bottom: 1.6rem;
  }

  .container :global(.timer-container) {
    display: flex;
    justify-content: flex-end;
    padding: 0.4rem;
  }

  .merchant-bg {
    background: no-repeat top center fixed;
    background-size: cover;
  }
</style>
