<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import api from '../../api';
  import { general as generalStrings, rakuten as rakutenStrings } from '../../resources/strings';
  import { emailBirthdayShape, emailBirthdayInitialValues } from './validation';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  const dispatch = createEventDispatcher();

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: emailBirthdayInitialValues,
    validationSchema: emailBirthdayShape,
    onSubmit: (submittedForm) =>
      api
        .postCode(
          $transaction.id,
          'emailCode',
          JSON.stringify({
            emailCode: submittedForm.emailCode,
            birthday: submittedForm.birthday,
          }),
        )
        .then(() => {
          timer.resetTimer();
          dispatch('verificationSubmit');
        }),
  });
</script>

<form class="container" on:submit={handleSubmit}>
  <Input
    label={rakutenStrings.emailCode}
    focus
    id="emailCode"
    name="emailCode"
    type="text"
    bind:value={$form.emailCode}
    error={$errors.emailCode}
    touched={$touched.emailCode}
    {handleChange}
  />
  <Input
    label={rakutenStrings.birthday}
    id="birthday"
    name="birthday"
    type="date"
    bind:value={$form.birthday}
    error={$errors.birthday}
    touched={$touched.birthday}
    {handleChange}
  />

  {#if $localError.verification}
    <p class="error">{$localError.verification}</p>
  {/if}

  <div class="button-container">
    <Button type="submit">{generalStrings.next}</Button>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 0 2rem;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .error {
    color: red;
  }
</style>
