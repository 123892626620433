import { readable } from 'svelte/store';
import { getBanks } from '../api';
import { setError } from './error.store';
import { isShowAll, getFrameData } from '../utils/locationUtils';
import filterByQuery from '../utils/searchQueryUtils';

const MERCHANT_TEST_LIST = [
  {
    name: 'test',
    nameJapanese: 'Test Transaction',
    nameEnglish: 'Test',
    avaiable: true,
    maintenance: { enabled: false },
  },
  {
    name: 'manual',
    nameJapanese: 'Test Manual',
    nameEnglish: 'Test Manual',
    maintenance: { enabled: false },
  },
  {
    name: 'testFailed',
    nameJapanese: 'Test Failed Transaction',
    nameEnglish: 'Test Failed Transaction',
    maintenance: { enabled: false },
  },
];

let fetchedBanksCache = [];

let setBanks;

export const banks = readable([], function start(set) {
  setBanks = set;
  if (APH_ENV === 'test' && !isShowAll()) {
    // Test env - displays test transaction only if query is not set to ?showall=true

    set(MERCHANT_TEST_LIST);
    fetchedBanksCache = MERCHANT_TEST_LIST;
  } else if (fetchedBanksCache.length > 0) {
    // Prevent multiple fetches on return to bank list
    set(fetchedBanksCache);
  } else {
    const transactionData = getFrameData();
    getBanks(transactionData.merchant_id)
      .then((fetchedBanks) => {
        if (fetchedBanks.length === 0) {
          setError('InternalError');
        } else {
          set(fetchedBanks);
          fetchedBanksCache = fetchedBanks;
        }
      })
      .catch((e) => {
        setError('connectionLost');
      });
  }

  return function stop() {};
});

export const setSearchQuery = (filter) => {
  const filterdList = filterByQuery(fetchedBanksCache, filter);

  setBanks(filterdList);
};
