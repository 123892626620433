<script>
  import { onMount } from 'svelte';

  import Status from '../../containers/Status/Status.svelte';
  import Loading from '../../components/Loading.svelte';

  import api from '../../api';

  import { transaction } from '../../stores/transaction.store';
  import { progress } from '../../stores/transaction-progress.store';
  import { setError, errors as errorsConst, updateError } from '../../stores/error.store';
  import { state, updateState } from '../../stores/state.store';

  async function handleStateChange() {
    try {
      await updateState($transaction.id);
    } catch {
      $state.current = 'connectionLost';
    }
    updateError($state.current);
  }

  onMount(async () => {
    await api.startFakeTransaction($transaction.id, 'SUCCESS').then(async (res) => {
      if (res?.ok === false) {
        $progress = 0;
        const data = await res.json();
        if (data.current) {
          setError(data.current, data);
        } else {
          setError(errorsConst.Unknown);
        }
      } else {
        handleStateChange();
      }
    });
  });
</script>

{#if $state.transactionResult}
  <Status type="Success" message="Success" />
{:else}
  <Loading />
{/if}
