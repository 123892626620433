import { errorStrings } from '../../../../shared/error-map';

export const errors = errorStrings;

export const verificationText = {
  otp: {
    title: 'ワンタイムパスワード',
    placeholder: 'ワンタイムパスワード入力',
  },
  emailCode: {
    title: 'EMAILで送られたコード',
    placeholder: 'EMAILで送られたコード入力',
  },
  smsCode: {
    title: 'SMSで送られたコード',
    placeholder: 'SMSで送られたコード入力',
  },
  custom: {
    title: '質問',
  },
  hiddenChar: {
    title: 'SMSで送られたコード',
    placeholder: 'SMSで送られたコード入力',
  },
  telephone: {
    title: '電話番号',
    placeholder: '（例）1-123-4567-8901',
  },
  appAuth: {
    title: 'ソフトウェアトークンのパスワード',
    placeholder: 'パスワード入力',
  },
};

export const mizuho = {
  userId: 'お客さま番号',
  userIdPlaceholder: 'お客さま番号',
  password: 'ログインパスワード',
  secondPIN: '第2暗証番号',
  procedure: '【手順】',
  otpSteps: [
    '１．ワンタイムパスワードカードの「３」のボタンを押してください。',
    '２．ワンタイムパスワードカードに振込先口座番号を入力してください。',
    '３．「OK」ボタンを押してください。',
    '４．ワンタイムパスワードカード上に表示されるワンタイムパスワード８桁をみずほダイレクトの画面上に入力してください。',
  ],
  emailCode: '振込ワンタイムパスワード追加認証',
  emailCodePlaceholder: '認証用暗証番号(半角数字5桁)',
};

export const mufg = {
  password: 'ログインパスワード',
  passwordPlaceholder: '半角英数字・記号 4~16桁',
  branch: '店番',
  branchPlaceholder: '半角数字3桁',
  account: '口座番号',
  accountPlaceholder: '半角数字7桁',
  contact: 'ご契約番号',
  contactPlaceholder: '半角数字',
  accountSelection: '店番号・口座番号',
};

export const smbc = {
  password: 'ログイン暗証',
  passwordPlaceholder: '半角英数字',
  branch: '店番',
  branchPlaceholder: '店番号',
  account: '口座番号',
  accountPlaceholder: '口座番号',
  contact: '契約者番号',
  contactPlaceholder: '契約者番号',
  accountSelection: '店番号・口座番号（普通預金）',
  withdrawalAccountSelection: '出金口座の選択',
  telephoneTitle: '本人確認中です',
  procedure1: '上記のアルファベットが読み上げられることを必ずご確認ください。',
  procedure2: '電話による本人確認が完了しましたら「次へ」を押してください。',
  qrCodeTitle: '二次元コードを読み取ってログイン ',
  qrCodeDescription:
    'SMBCセーフティパスを登録した端末をご用意いただき、カメラアプリ等で以下の二次元コードを読み取ってください。',
  qrCodeLogin:
    'SMBCセーフティパスを登録した端末で承認ボタンをタップした後、以下の「承認操作を完了しました」よりお進みください。',
  qrCodeWarning: '三井住友銀行アプリでのログイン承認後にお進みください。',
};

export const paypay = {
  password: 'ログインパスワード',
  passwordPlaceholder: '半角英数記号32文字以内',
  branch: '店番',
  branchPlaceholder: '半角数字3桁',
  account: '口座番号',
  accountPlaceholder: '半角数字7桁',
  otpForTransaction: 'ワンタイムパスワード トランザアクション',
  accountSelection: '店番号・口座番号',
  loginIdSelection: 'ログインID設定済の方',
  loginId: 'ログインID',
  loginIdPlaceholder: '半角英数字6～32文字',
  phoneVerification: 'ご登録電話番号から90秒以内に以下の番号に電話をおかけください',
  phoneVerificationConfirmation: '通話が終わりましたら［次へ］を選択してください',
};

export const rakuten = {
  userId: 'ユーザID',
  userIdPlaceholder: '半角英数字の8桁～12桁',
  branch: '支店番号',
  branchPlaceholder: '3桁半角数字',
  account: '口座番号',
  accountPlaceholder: '7桁半角数字',
  emailCode: 'ワンタイムキー',
  pin: '暗証番号',
  birthday: '生年月日',
  loginPassword: 'ログインパスワード',
  loginPasswordPlaceholder: 'すべて半角',
  cardNumberInstruction: '入力方法（セキュリティカードを使った認証方法）',
};

export const resona = {
  userId: 'ログインID',
  userIdPlaceholder: '半角英数字',
  loginPassword: 'ログインパスワード',
  customQuestionInfo1: '全角ひらがな',
  customQuestionInfo2: '3～10文字でご記入ください',
  passwordInfo: '半角英数字',
  newAppTitle: 'りそなグループアプリ',
  appInstruction1: '①りそなグループアプリを起動し、マイページを開きます。',
  appInstruction2: '②マイページ内の「マイゲートの取引を承認する」ボタンを押下します。',
  appInstruction3:
    '③「承認待ちの取引」の取引内容を確認し、「承認する」ボタンをタップしてください。',
  appConfirm: '申請確認後、こちらを押してください。',
  procedure: '以下手順1～3に沿って新型ハードウェアトークンの操作をお願いします。',
  otpSteps: [
    '新型ハードウェアトークンの③のボタンを押して、振込先口座の口座番号７桁を新型ハードウェアトークンに入力してください。口座番号が７桁未満の場合は、頭に０を入力して７桁で入力してください。',
    'ＯＫボタンを押してください。',
    '新型ハードウェアトークンに表示された７ 桁の数字を本画面の入力欄に入力してください。',
  ],
};

export const sbi = {
  login: 'ユーザーネーム',
  loginPassword: 'WEBログインパスワード',
  codeCardTitle: '認証番号表',
  codeCardInfo: '※ 認証番号表はキャッシュカードまたは認証番号カードに表示されています。',
  appAuthTitle: '＜スマート認証NEO＞',
  appAuthTransactionWarning: '取引承認',
  appAuthInfo: '取引承認依頼をアプリに送信しましたので、アプリで承認してください。',
  appAuthTrxNumber: '承認番号',
  appAuthLoginInfo: 'ログイン承認依頼をアプリに送信しましたので、アプリで承認してください。',
};

export const gmoazora = {
  userId: 'ログインID',
  userIdPlaceholder: 'ログインIDまたはログイン名',
  loginPassword: 'ログインパスワード',
  loginPasswordPlaceholder: '6～10文字の半角英数記号',
  trxpassword: '取引パスワード',
  appAuthTitle: 'アプリ認証',
  appAuthInfo: '取引承認依頼をアプリに送信しましたので、アプリで承認してください。',
  birthday: '生年月日',
  birthdayInputPlaceholder: '8文字の半角数字',
  birthdayAuthTitle: '本人確認のため、生年月日の入力をお願いします。',
  birthdayAuthExample: '例：19800105（1980年01月05日生まれの場合）',
};

export const aujibun = {
  customerNumber: 'お客さま番号',
  fiveDigits: '※5桁',
  password: 'ログインパスワード',
  login: 'ログイン',
  appAuthTitle: 'アプリ認証',
  appAuthInfo:
    'じぶん銀行スマートフォンアプリにログインし、取引内容をご確認のうえ、承認してください。',
  chooseOtp: 'ワンタイムパスワードの送信先をお選びください。',
};

export const japanpost = {
  loginMethodTitle: 'ログイン方法',
  passwordMethod: 'パスワードでログイン',
  biometricsMethod: '生体認証でログイン',
  customerNumber: 'お客さま番号',
  fiveDigits: '5桁',
  fourDigits: '4桁',
  loginPassword: 'ログインパスワード',
  qrCodeDescription:
    'お持ちのスマートフォンまたはタブレットで「ゆうちょ認証アプリ」を起動し、以下のQRコードを読み取ってください。',
  qrCodeLogin: '「ゆうちょ認証アプリ」による認証が完了しましたら、「次へ」を押してください。',
  qrCodeTitle: 'ログイン（QRコード読取）',
  procedure: '【手順】',
  otpSteps: [
    'ボタン3を押 します。',
    '送金先の「口座番号」を数字キーで入力してください。',
    '送金先口座番号の先頭が「0」で始まっている場合は、先頭の「0」を全て除いて入力してください。',
    '矢印ボタンを押すと表示されるワンタイムパスワード（数字7桁）を画面に入力してください。',
  ],
  informationOnBiometricLogin:
    'モバイルアプリを使用している場合は、生体認証によるログインを選択してください。モバイルアプリを設定した状態でパスワードによるログインを選択すると、取引を完了することができません。',
  prepareMobileDevice:
    'QRコードをスキャンするために、別のデバイスでモバイルアプリを準備してください。',
};

export const seven = {
  login: 'ログオンID',
  loginPlaceholder: '例：abc123',
  password: 'ログオンパスワード',
  loginButton: 'ログオン',
  appAuthTitle: 'アプリ認証',
  appAuthInfo: 'スマートフォンアプリにログインして確認してください。',
  appApprovalTitle: 'Myセブン銀行（スマートフォンアプリ）で承認が必要です。',
  appApprovalText:
    '以下の承認依頼番号と、アプリに表示されている承認依頼番号が一致することをご確認ください。',
  appApprovalCode: (approvalCode = '') => `承認依頼番号 ${approvalCode}`,
  approvalSteps: [
    'アプリを起動して、「承認履歴」を開き、該当のお手続きを選択します。',
    '手続内容が正しいことを確認し、 「承認する」を選択します。',
  ],
  appCodePlaceholder: 'ワンタイムパスワード',
  appVerifiactionSteps: [
    '手続内容が正しいことを確認し、「ワンタイムパスワードを表示す る」を選択します。',
    '銀行のモバイルアプリから提供された6桁のワンタイムパスワードを入力してください。',
  ],
};

export const manualBank = {
  title: '取引の詳細',
  displayData: {
    recipientName: '受取人名',
    bankName: '銀行名',
    bankCode: '銀行コード',
    branchName: '支店名 / コード',
    accountNumber: '口座番号',
    amount: '金額',
    confirmationId: '確認用ID (入力不要)：',
  },
  copy: 'コピー',
  descriptionTitle: '銀行送金でお支払いいただく際には、毎回以下の点にご注意下さい。',
  descriptionOne:
    '必ずご自身の銀行口座から送金を行って下さい。送金人の名前は変更しないでください。',
  descriptionTwo:
    '入金情報の登録時のポップアップウィンドウに表示されている金額を、過不足なく送金してください',
  descriptionThree:
    'ご入金の度にこちらのページから最新の情報をご確認いただき、必ず最新の銀行口座へ送金いただくようお願いいたします。（最新の銀行口座へ送金されなかった場合、ご入金は反映されません。）',
  descriptionWarningOne:
    'これらのお手続きに不備がある場合は、振込が正しく反映されない、若しくは通常より時間が掛かる等の場合が御座います。',
  descriptionWarningTwo:
    '銀行送金手続きの後、24時間以内に口座に入金金額が反映されない場合は、カスタマーサポートまでご連絡ください。',
};

export const saitamaResona = {
  userId: 'ログインID',
  userIdPlaceholder: '半角英数字',
  loginPassword: 'ログインパスワード',
  customQuestionInfo1: '全角ひらがな',
  customQuestionInfo2: '3～10文字でご記入ください',
  passwordInfo: '半角英数字',
  newAppTitle: 'りそなグループアプリ',
  appInstruction1: '①りそなグループアプリを起動し、マイページを開きます。',
  appInstruction2: '②マイページ内の「マイゲートの取引を承認する」ボタンを押下します。',
  appInstruction3:
    '③「承認待ちの取引」の取引内容を確認し、「承認する」ボタンをタップしてください。',
  appConfirm: '申請確認後、こちらを押してください。',
  procedure: '以下手順1～3に沿って新型ハードウェアトークンの操作をお願いします。',
  otpSteps: [
    '新型ハードウェアトークンの③のボタンを押して、振込先口座の口座番号７桁を新型ハードウェアトークンに入力してください。口座番号が７桁未満の場合は、頭に０を入力して７桁で入力してください。',
    'ＯＫボタンを押してください。',
    '新型ハードウェアトークンに表示された７ 桁の数字を本画面の入力欄に入力してください。',
  ],
};

export const sony = {
  branchNumber: '店番号',
  branchNumberPlaceholder: '',
  login: '口座番号',
  loginPlaceholder: '',
  password: 'ログインパスワード	',
  passwordPlaceholder: '',
  transactionPin: '取り引き暗証番号',
  appAuthHeading: 'ソニー銀行アプリでお振り込み内容をご確認ください',
  appAuthSteps: [
    '（1）ソニー銀行アプリにログインしてください。',
    '（2）『振込内容 再確認』画面が表示されますので、お振込先、ご金額などに相違がないかご確認のうえ、問題がなければ「振込」を押してください。表示内容に相違がある場合は「取り消し」を押してください。',
  ],
  appAuthConfirmation:
    'スマートフォンのアプリで転送を確認したら、次のボタンをクリックしてください。',
  otpInstruction:
    'トークン表面のボタンを押してから30秒以内に数字をご入力のうえ、「決定」ボタンをクリックしてください。',
  authorizationNumberInstructions:
    'キャッシュカード裏面をご参照ください。10ケタの番号（製造番号）から指定する2つの数字をそれぞれ入力してください。',
};

export const general = {
  amountJPY: '日本円での合計金額',
  yen: '円',
  selectBank: '銀行を選択してください',
  bank: '銀行',
  bankSelection: '銀行選択',
  back: '他の銀行を選択する',
  backToLogin: '戻る',
  next: '次へ',
  amount: '金額',
  transaction: 'トランザクション',
  acceptSelection: '次へ進む',
  transactionFailure: '失敗しました！',
  transactionSuccess: 'おめでとう！',
  transactionSuccessInfo: 'お振込みが完了しました。',
  transactionSuccessWarning:
    'この後、お振込みをキャンセルされる場合、この決済サービスをご利用いただけなくなります。',
  transferConfrimation: '閉じる',
  bankDisabled: '銀行によるメンテナンス作業のため、休止中。',
  depositAmount: '入金額',
  transactionFee: '取引手数料',
  totalAmount: '請求合計金額',
  searchInputPlaceholder: '銀行を検索する',
  retryTrx: '再試行',
  operatingHours: (startTime, endTime, bankName) =>
    `平日${endTime}～翌日${startTime}および土・日・祝日は${bankName}から他行宛ての振込みは受付できません。平日${startTime}～${endTime}までの間に振込を行ってください。`,
  notificationTexts: () => [
    'ご入金の際は、ご本人名義の銀行口座からのご入金に限らせていただきます。口座名義人名が第三者の場合、お預かりできません。',
    'お振込みいただく金額は、必ずお振込み先の口座への振込を完了させてください。',
    'お振込み金額の着金が確認された後、お客さまの口座へ反映されます。',
  ],
};

export const aeon = {
  login: '契約者ID',
  loginPlaceholder: '',
  password: 'ログインパスワード',
  passwordPlaceholder: '',
  transactionPassword: '取引パスワード',
  secretQuestionPlaceholder: '「ひらがな」で入力してください。（2～10文字以内）',
  receptionCode: '受付コード',
  emailConfirmationInstruction:
    '表示のメールアドレスに受付コードを通知するメールが配信されますので、メールに記載の受付コードを入力し、「次へ」ボタンを押してください。',
  appOtpAuthSteps: [
    '① 通帳アプリを開く',
    '② 「パスワード」(画面右下)をタップ',
    '③ ワンタイムパスワードを確認',
  ],
};

export const fukuoka = {
  password: 'パスワード',
  passwordPlaceholder: '半角英数8～16文字',
  branch: '店番',
  branchPlaceholder: '',
  account: '口座番号',
  accountPlaceholder: '',
  contact: '会員番号',
  contactPlaceholder: '',
  accountSelection: '店番+口座番号でログイン',
  contractSelection: '会員番号でログイン',
  phoneVerification: '銀行にお届けの電話番号から承認用電話番号へお電話ください。',
  phoneVerificationConfirmation: '通話が終わりましたら［次へ］を選択してください',
};

export const suruga = {
  userName: 'ユーザーネーム',
  userNamePlaceholder: '1桁以上最大32桁の半角英数字',
  password: 'ログインパスワード',
  passwordPlaceholder: '4桁以上最大32桁の半角英数字',
  customQuestionPlaceholder: '全角20文字以内',
};

export const hokkaido = {
  password: 'ログインパスワード',
  passwordPlaceholder: '（半角英数字6～12桁）',
  branch: '店番号',
  branchPlaceholder: '（半角数字3桁）',
  account: '口座番号',
  accountPlaceholder: '（半角数字7桁）',
  contact: 'ログインネーム',
  contactPlaceholder: '（半角英数字6～12桁）',
  accountSelection: '店番号・口座番号',
};

export const hiroshima = {
  password: 'ダイレクトバンキング暗証番号',
  passwordPlaceholder: '（半角数字4桁）',
  login: 'ご契約者番号',
  loginPlaceholder: '（半角数字10桁）',
};

export const chiba = {
  password: 'ログオンパスワード',
  passwordPlaceholder: '（半角英数6～10桁）',
  login: 'ご契約者番号',
  loginPlaceholder: '（半角数字10桁）',
};

export const kagoshima = {
  password: 'ログインパスワード',
  passwordPlaceholder: 'ログインパスワード',
  login: '利用者ID',
  loginPlaceholder: '利用者ID',
};

export const tokyostar = {
  password: 'ログインパスワード',
  passwordPlaceholder: 'ログインパスワード',
  login: 'ログインID',
  loginPlaceholder: 'ログインID',
};

export const awa = {
  contact: 'ご契約番号',
  contactSelection: 'ご契約番号でログオン',
  accountSelection: '代表口座番号でログオン',
  branch: '店番号（半角数字3桁）',
  account: '口座番号（半角数字ハイフンなし）',
  password: 'ログオンパスワード',
};

export const kyoto = {
  contact: '会員番号',
  contactPlaceholder: '（半角数字10桁）',
  contactSelection: '会員番号',
  accountSelection: '支店番号・口座番号',
  branch: '支店番号',
  branchPlaceholder: '（半角数字3桁）',
  account: '口座番号（半角数字ハイフンなし）',
  accountPlaceholder: '（半角数字）',
  password: 'ログインパスワード',
};

export const hokuriku = {
  contact: 'ログインID',
  contactPlaceholder: '（半角英数字混在6～10桁）',
  branch: '支店番号',
  branchPlaceholder: '（半角数字3桁）',
  account: '口座番号',
  accountPlaceholder: '（半角数字7桁）',
  password: 'ログインパスワード',
  passwordPlaceholder: '（半角英数字混在6～10桁）',
  contactSelection: 'ログインID',
  accountSelection: '支店番号・口座番号',
};

export const kansaimirai = {
  contact: 'ログインID',
  contactPlaceholder: '（旧ダイレクトID）',
};

export const musashino = {
  contact: '会員番号',
  contactPlaceholder: '（半角数字10桁）',
  password: 'ログインパスワード',
  passwordPlaceholder: '（半角英数6桁）',
};

export const shinsei = {
  contact: '店番号・口座番号',
  contactPlaceholder: '(半角数字１０桁)',
  password: 'パワーダイレクトパスワード',
  passwordPlaceholder: '(半角英数６～１２桁)',
};

export const shizuoka = {
  contact: 'ログインID',
  contactPlaceholder: '（半角英数字6～12桁）',
  branch: '店番',
  branchPlaceholder: '（半角数字3桁）',
  account: '口座番号',
  accountPlaceholder: '（半角数字7桁）',
  password: 'ログインパスワード',
  passwordPlaceholder: '（半角英数字6～12桁）',
  contactSelection: 'ログインID',
  accountSelection: '支店・口座番号',
};

export const yokohama = {
  password: 'ログインパスワード',
  passwordPlaceholder: '英数字6〜12桁',
  branch: '店番号',
  branchPlaceholder: '半角数字3桁',
  account: '口座番号',
  accountPlaceholder: '半角数字7桁',
  contact: '契約番号',
  contactPlaceholder: '半角数字10桁',
  accountSelection: '店番号・口座番号',
};
