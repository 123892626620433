<script>
  import { createEventDispatcher, onMount } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import api from '../../api';
  import { verificationText, mizuho, general as generalStrings } from '../../resources/strings';
  import {
    customShape,
    customInitialValues,
    otpShape,
    otpInitialValues,
    hiddenCharShape,
    hiddenCharInitialValues,
    emailCodeInitialValues,
    emailCodeShape,
  } from './validation';
  import { transaction } from '../../stores/transaction.store';
  import { localError } from '../../stores/error.store';
  import timer from '../../stores/timer.store';
  import Button from '../../components/Button.svelte';
  import Input from '../../components/Input.svelte';

  export let codeType;
  export let customQuestion;

  let accountNumber = '';

  $: strings = verificationText[codeType];

  const dispatch = createEventDispatcher();

  onMount(() => timer.setNewTimer(115));

  if (codeType === 'otp') {
    accountNumber = customQuestion;
  }

  const validations = {
    custom: {
      initialValues: customInitialValues,
      shape: customShape,
    },
    hiddenChar: {
      initialValues: hiddenCharInitialValues,
      shape: hiddenCharShape,
    },
    otp: {
      initialValues: otpInitialValues,
      shape: otpShape,
    },
    emailCode: {
      initialValues: emailCodeInitialValues,
      shape: emailCodeShape,
    },
  };

  onMount(() => timer.setNewTimer(5 * 60 - 5));

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: validations[codeType].initialValues,
    validationSchema: validations[codeType].shape,
    onSubmit: (submittedForm) =>
      api
        .postCode(
          $transaction.id,
          codeType,
          codeType === 'hiddenChar'
            ? JSON.stringify({
                pinCode: [
                  submittedForm.letter1,
                  submittedForm.letter2,
                  submittedForm.letter3,
                  submittedForm.letter4,
                ],
              })
            : submittedForm[codeType],
        )
        .then(() => {
          dispatch('verificationSubmit');
          timer.resetTimer();
        }),
  });
</script>

<form on:submit={handleSubmit}>
  <div class="container">
    {#if codeType === 'custom'}
      <Input
        name={codeType}
        label={customQuestion}
        focus
        id={codeType}
        type={customQuestion !== mizuho.password ? 'text' : 'password'}
        placeholder={strings.placeholder}
        bind:value={$form.custom}
        error={$errors.custom}
        touched={$touched.custom}
        {handleChange}
      />
    {:else if codeType === 'otp'}
      <div>
        <Input
          label={`口座番号 ${accountNumber}`}
          name={codeType}
          focus
          id={codeType}
          type={customQuestion !== mizuho.password ? 'text' : 'password'}
          placeholder={strings.placeholder}
          bind:value={$form.otp}
          error={$errors.otp}
          touched={$touched.otp}
          {handleChange}
        />

        {#if $localError.verification}
          <p class="error">{$localError.verification}</p>
        {/if}

        <div class="instruction-header">{mizuho.procedure}</div>
        {#each mizuho.otpSteps as step}
          <div>{step}</div>
        {/each}
      </div>
    {:else if codeType === 'hiddenChar'}
      <div class="hiddenChar-container">
        <div>
          <p>{mizuho.secondPINdescription1}</p>
          <p>{mizuho.secondPINdescription2}</p>
        </div>
        <div class="hiddenChar-inputsContainer">
          {#each JSON.parse(customQuestion).customQuestion as letter, i}
            <div class="input-container">
              <Input
                name={codeType}
                label={`${letter}番目`}
                focus
                id={`letter${i + 1}`}
                type="password"
                placeholder={letter}
                bind:value={$form[`letter${i + 1}`]}
                error={$errors[`letter${i + 1}`]}
                touched={$touched[`letter${i + 1}`]}
                {handleChange}
              />
            </div>
          {/each}
        </div>
      </div>
    {:else if codeType === 'emailCode'}
      <Input
        label={mizuho.emailCode}
        name={codeType}
        focus
        id={codeType}
        type="text"
        placeholder={mizuho.emailCode}
        bind:value={$form.emailCode}
        error={$errors.emailCode}
        touched={$touched.emailCode}
        {handleChange}
      />
    {/if}

    {#if $localError.verification && codeType !== 'otp'}
      <p class="error">{$localError.verification}</p>
    {/if}

    <div class="button-container">
      <Button type="submit">{generalStrings.next}</Button>
    </div>
  </div>
</form>

<style>
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  .instruction-header {
    margin: 20px 0;
    text-align: center;
  }

  .input-container {
    padding: 10px;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .hiddenChar-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .hiddenChar-inputsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .error {
    color: red;
  }
</style>
