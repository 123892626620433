<script>
  import { onMount } from 'svelte';

  import api from '../../api';

  import { transaction } from '../../stores/transaction.store';
  import { state, updateState } from '../../stores/state.store';
  import { updateError } from '../../stores/error.store';

  import Status from '../../containers/Status/Status.svelte';
  import Loading from '../../components/Loading.svelte';

  let loading = true;

  async function handleStateChange() {
    try {
      await updateState($transaction.id);
    } catch {
      $state.current = 'connectionLost';
    }
    updateError($state.current);
  }

  onMount(async () => {
    await api.startFakeTransaction($transaction.id, 'FAILED').then(() => handleStateChange());
    loading = false;
  });
</script>

{#if $state.current === 'Unknown'}
  <Status type="Failed" message="Failed" />
{:else}
  <Loading />
{/if}
