<script>
  import { createEventDispatcher } from 'svelte';
  import { createForm } from 'svelte-forms-lib';
  import { fly } from 'svelte/transition';
  import { transaction } from '../../stores/transaction.store';
  import { selectedBank } from '../../stores/selected-bank.store';
  import { localError, setError, errors as errorsConst } from '../../stores/error.store';
  import { startProgress, progress } from '../../stores/transaction-progress.store';
  import Input from '../../components/Input.svelte';
  import LoginButtonsGroup from '../../containers/LoginButtonsGroup.svelte';
  import api from '../../api';
  import { aujibun as bankStrings } from '../../resources/strings';
  import { loginShape, loginInitialValues } from './validation';

  const dispatch = createEventDispatcher();

  let submitted = false;

  const { form, errors, touched, handleChange, handleSubmit } = createForm({
    initialValues: loginInitialValues,
    validationSchema: loginShape,
    onSubmit: (submittedForm) => {
      if (!submitted) {
        submitted = true;
        return api
          .startCrawler(
            $transaction.id,
            {
              accountNumber: submittedForm.customerNumber,
              password: submittedForm.password,
            },
            $selectedBank.name,
          )
          .then(async (res) => {
            if (res?.ok === false) {
              $progress = 0;
              const data = await res.json();
              if (data.current) {
                setError(data.current, data);
              } else {
                setError(errorsConst.Unknown);
              }
            } else {
              startProgress();
              dispatch('loginSubmit');
            }
          });
      }
    },
  });
</script>

<form on:submit={handleSubmit} class="container">
  <div>
    <div class="login-form" in:fly={{ x: -200, duration: 300 }}>
      <div class="customer-number-inputs">
        <Input
          label={`${bankStrings.customerNumber}`}
          id="customerNumber"
          name="customerNumber"
          type="tel"
          maxlength="10"
          placeholder={bankStrings.tenDigits}
          bind:value={$form.customerNumber}
          error={$errors.customerNumber}
          touched={$touched.customerNumber}
          {handleChange}
        />
      </div>
      <Input
        name="password"
        type="password"
        label={bankStrings.password}
        placeholder=""
        bind:value={$form.password}
        error={$errors.password}
        touched={$touched.password}
        {handleChange}
      />
    </div>
    {#if $localError.login}
      <p class="error">{$localError.login}</p>
    {/if}
    <LoginButtonsGroup on:back />
  </div>
</form>

<style>
  .container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
    padding: 0 2rem;
  }
  .login-form {
    display: flex;
    flex-direction: column;
  }
  .customer-number-inputs {
    display: flex;
    gap: 2rem;
    padding-bottom: 2rem;
  }
  .error {
    color: red;
  }
</style>
