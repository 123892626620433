const errorStrings = {
  incorrectCredentials: 'ログイン情報が正しくありません。',
  incorrectBranch: '支店番号が正しくありません',
  incorrectAccount: 'アカウント番号が正しくありません',
  incorrectPassword: 'パスワードが正しくありません',
  invalidEmailCode: '無効なメール確認コード',
  invalidOtpCode: '無効なワンタイムパスワード(OTP)コード',
  hiddenCharRetry: 'ワンタイムパスワードの入力に誤りがあります。',
  invalidData: '無効なデータ。リトライ',
  invalidSMSCode: '無効なSMSコード',
  noMoney: '残高不足',
  loginBlockedMUFG:
    'ダイレクトパスワードにロックがかかっているため、ご利用いただけません。',
  loginAccountBlocked: 'アカウントがブロックされているようです',
  loginAccountInactive: '当行所定の時間が経過したため、ログインできません',
  loginAccountNotUsed:
    '本口座は長い間お取引がなかったため、休眠預金として管理し、「楽天銀行口座取引規定」に基づいてお取引を一時停止しております',
  accountTemporaryBlocked:
    'アカウントは一時的にブロックされています。後で試してください',
  insufficientBalance: '残高不足です',
  serviceUnavailable: 'サービスは一時的に利用できません',
  // internalError: '内部エラー',
  internalError: 'あなたの取引は完了できませんでした。',
  TokenLocked: 'お客さまのトークンはロックされています。',
  WrongAuthData: 'ご入力いただいた認証情報が正しくありません。',
  AccountBlocked: 'アカウントがブロックされています',
  limitExceeded: '1日の取引限度額を超えています',
  minTransferLimit: '送金額が下限額を下回っています',
  maxTransferLimit: '送金金額が上限額を超えています',
  onlyUSDTransfers: '現在、米ドル建て取引のみ可能です',
  multiTransactionsMufg:
    '一定時間、操作が確認できなかったため、セキュリティの観点からお取引を中断させていただきました。お取引を行うには、再度ログインしてください。',
  incorrectLength: '文字数が違います',
  incorrectLength10Digits: 'お客さま番号は「10桁」の形式でご入力ください。',
  incorrectLength6To12Charts: '半角英数字6～12文字でご入力ください。',
  onlyNumbers: '数字のみ入力可能',
  fieldRequired: '必須項目です',
  onlyKana: 'カタカナで入力してください',
  timeout: 'タイムアウト',
  accountLimitExceeded: '取引金額が1日あたりのご利用限度額を超えています。',
  accountAmountLimitExceeded:
    '取引金額が1日あたりのご利用限度額を超えています。 振込限度額を変更してください。',
  dailyLimitWillBeExceeded:
    '1日あたりの振込限度額を超えるため、入力された金額での振込みはできません。',
  existTransactionToApprove:
    '承認待ちの取引があります。一度にスマホ認証予約できるのは1取引のみです。',
  blockedInternetBanking: 'インターネットバンキングロックが設定されています。',
  lockedConfirmationNumber: '確認番号がロックされています。',
  loginServiceUnavailable:
    'ただ今の時間はこちらのサービスはご利用いただけません。',
  ipRestriction: '登録された利用環境が異なるため、ご指定のお取引はできません',
  smbcIncorrectContact: '契約者番号に誤りがあります（例）12345-67890',
  cantCompleteTrx: '銀行側からのエラーにより取引を完了できませんでした。',
  accountUninitializedSMBC: 'ワンタイムパスワードの初期設定が必須です',
  timeoutOtpPayPay:
    'タイムアウト または 上書きログインが行われ、本接続は切断されました。 もう一度最初からお試しください。',
  timeoutOtpMufg:
    '一定時間、操作が確認できなかったため、セキュリティの観点からお取引を中断させていただきました。',
  timeoutEmailCodeGMO:
    'URLの有効期限が切れています。最初からお手続きください。',
  timeoutAppAuthGMO:
    'お取引の認証ができませんでした。最新の状態をご確認ください。振込の受付状態は振込状況照会ページからご確認ください',
  answersLimitRakuten:
    '認証情報の誤入力が所定回数を超えました。一時的にログインを制限させていただきます。',
  answersLimitGMO:
    'お取引の認証を一定回数失敗されたため、お取引を制限させていただきます。翌日に解除されますのでお待ちください。',
  incorrectOtpLimitJapanPost:
    '「ワンタイムパスワード」のご指定が間違っています。一定回数誤りが続きましたのでログアウトいたしました。',
  accountUninitializedMUFG:
    '三菱ＵＦＪダイレクトのログインパスワードが登録されていないため、お取引できません。',
  otpInactiveMUFG:
    'ワンタイムパスワードの利用登録を行っていないため、このお取引は利用できません。',
  serviceUnavailableMufg: 'お取り扱いできません。',
  incorrectTelephoneFormat:
    '電話番号の形式が正しくありません（例）1-123-4567-8901',
  appAuthRetry: 'お客さまのリクエストが正しく受付けられませんでした',
  goToBankSite: '銀行のウェブページにアクセスしてください',
  loginAccountUninitializedRakuten:
    '一旦振込みを中止して楽天銀行オンラインバンクに改めてログインし、お客様カードをご記入下さい。お客様カードをご記入後、再度振込みをお試しください。',
  tryLaterRakuten:
    '只今アクセスが大変混みあっております。恐れ入りますがしばらくたってから再度ご利用ください。',
  loginAttemptsExceededRakuten:
    '認証情報の誤入力が所定回数を超えました。一時的にログインを制限させていただきます。',
  transfersDisabledResona:
    'あなたのアカウントからはオンライン送金は出来ません。',
  loginReset:
    '三菱UFJ銀行オンラインバンクに改めてログインし、ログインパスワードの再設定を行って下さい。ログインパスワードの再設定後、再度振込みをお試しください。',
  loginUnavailableResona: 'ログインできませんでした',
  transferPasswordError: '取引パスワードが間違っています。',
  transferCanceled: '取引は取消しされました。',
  loginAuthNotProvidedSbi:
    'お客さまのリクエストが正しく受付けられませんでした。',
  canceledByUserGMO: 'お取引の認証が取り消されました。',
  loginMaintenancePayPay:
    'ただいまメンテナンス中のため、ご利用いただくことができません。ご迷惑をおかけしますが、サービス再開後にご利用ください。',
  noQrCodeConfirmation: '「ゆうちょ認証アプリ」で認証を完了してください。',
  transferUnavailableJapanPost: 'バイオメトリクスを使用してログインする',
  loginServerCrowdedRakuten:
    '只今アクセスが大変混みあっております。恐れ入りますがしばらくたってから再度ご利用ください。',
  nightErrorRakuten:
    '夜間処理中のためお取引できません。しばらく時間をおいて再度お取引ください。',
  loginMaintenanceResona:
    'ただいまの時間はシステムメンテナンスのため、サービスを停止しております。',
  appNotActivatedJapanPost:
    '生体認証でのお取り扱いができませんでした。「ゆうちょ認証アプリ」を登録していない場合や、ゆうちょダイレクトの再申し込み・利用口座登録によりお客さま番号が変更されている場合は、「ゆうちょ認証アプリ」を再度登録のうえ、あらためてご利用ください。',
  maintenanceJapanPost: '現在、ご利用時間外のためお取り扱いいただけません。',
  invalidPhoneNumber: '無効な依頼人電話番号コード',
  dailyLimitZero:
    'ゆうちょダイレクトでの１日の送金限度額が０円に設定されていますので、送金できません。',
  transfersDisabledSaitamaResona:
    'あなたのアカウントからはオンライン送金は出来ません。',
  transferUnavailableResona:
    'お取扱いできません。スマホ認証もしくはハードウェアトークンへの切替完了後に再度お手続きください。',
  authentictionNoMoreAvailableSaitama:
    'ご利用中のハードウェアトークンまたはスマホ認証が利用できなくなりました。詳しくは、マイゲートサポートセンターまでお問合せください。',
  registerNewCreditCardSeven:
    'キャッシュカードを再発行しておりますので、新しいキャッシュカードのご利用開始登録のお手続きをお願いいたします。',
  otherSideMaintenancePayPay:
    '相手先金融機関のメンテナンス等により指定の振込日に振り込みができない可能性がございます。',
  automaticLogoutMizuho:
    '同一のお客さま番号で別のログインがあったため、自動的にログアウトされました。',
  incorrectRecipientNameMizuho:
    'お振込先の受取人名の入力方法が間違っています。',
  newPasswordNeededRakuten: 'ログインパスワードを新たに設定してください。',
  qrCommunicationError:
    '通信エラー等が発生し、ご依頼のお取扱を受け付けることができませんでした。同じQRコードを2回以上読み取った場合も、このエラーが表示されます。',
  transactionSuspended: 'ご利用の銀行から取引が停止されました。',
  dailyCumulativeLimitExceededJapanPost:
    '本日の送金累積額が5万円を超えるため、メールワンタイムパスワードを使用した即時送金はご利用いただけません。',
  notWithdrawalAccount: 'ご指定の口座は出金可能な口座ではありません。',
  appCanceledResona: 'アプリケーションで取引承認が確認されませんでした。',
  appCanceledSaitamaResona:
    'アプリケーションで取引承認が確認されませんでした。',
  loginMaintenanceMufg:
    'ただいま定例または臨時メンテナンスのため、お取り扱いしておりません。',
  emailCodeExpiredMufg: 'ワンタイムパスワードの有効期間を過ぎています。',
  secretUninitializedRakuten:
    'このオンライン口座は初期設定が完了していません。楽天銀行のWebページで口座設定を完了していただいた後、再度こちらのページから送金をお試しください。',
  maintenanceSbi:
    'ただいま定例または臨時メンテナンスのため、お取り扱いしておりません。',
  dailyLimitResona: '振込金額が１日あたりの振込限度額を超えました。',
  dailyLimitSaitamaResona: '振込金額が１日あたりの振込限度額を超えました。',
  withdrawalAccountUnavailableSMBC:
    'ご指定いただいた出金口座は、現在ご利用いただけません。',
  maintenanceAujibun:
    'ただ今、システムメンテナンス中のため、ご利用いただけません。',
  appAuthNotSetSaitamaResona:
    'りそなグループアプリをセットアップし、マイゲートと連携後再度お手続きください。',
  appAuthNotSetResona:
    'りそなグループアプリをセットアップし、マイゲートと連携後再度お手続きください。',
  tokenExpired:
    'お使いのソフトウェアトークンは使用期限が過ぎているため、ご利用いただけません。「各種変更・手続き」より新型ハードウェアトークンまたはスマホ認証の利用申込をおこなってください。',
  transferLimitPerTransactionAeon: '1回あたりの振込限度額を超えています。',
  transferLimitPerDayAeon: '1日あたりの振込限度額を超えています。',
  loginMaintenanceAeon:
    'ただ今、システムメンテナンス中のため、ご利用いただけません。',
  registerOneTimePasswordResona:
    '2017年1月16日よりマイゲートで振込、ネット振込サービス、ペイジー料金払込をご利用いただくには、ワンタイムパスワードが必須となりました。また、2017年9月11日よりWeb口座振替をご利用の一部のお客さまにもワンタイムパスワードが必須となりました。',
  registerOneTimePasswordSaitamaResona:
    '2017年1月16日よりマイゲートで振込、ネット振込サービス、ペイジー料金払込をご利用いただくには、ワンタイムパスワードが必須となりました。また、2017年9月11日よりWeb口座振替をご利用の一部のお客さまにもワンタイムパスワードが必須となりました。',
  confirmationPassLockedResona: '確認用パスワードロックアウト中',
  confirmationPassLockedSaitamaResona: '確認用パスワードロックアウト中',
  trxInProgressFukuoka:
    '前回のお取引が正しく終了していない可能性があります。お取引内容をご確認のうえ再度ご操作ください。（トップページ→メニュー一覧→取引履歴照会）',
  transferLimitPerDaySony: '1日あたりの振込限度額を超えています。',
  transactionErrorSony:
    'お手続き中に障害が発生しました。ただいまのお手続きは完了しなかった可能性があります。',
  transferRejectedSony: 'アプリケーションで取引承認が確認されませんでした。',
  systemErrorSBI:
    'システムエラーが発生しました。操作中のお取引が完了していない可能性があります。お取引が完了していない場合はあらためてお手続きください。',
  uninitializedOtpFuku:
    'お取引に必要なワンタイムパスワードの利用登録が完了していないため、ご利用いただけません。',
  hiraganaOnly: '「ひらがな」で入力してください。',
  alreadyLoggedInSuruga:
    '受取人口座確認を連続して行いましたので、ログアウトいたしました。',
  dailyLimitSuruga:
    '1日あたりの振込限度額を超えるため、入力された金額での振込はできません。',
  invalidOperationFukuoka:
    '無効な操作が行われました。ログイン画面から再度ご操作ください。',
  loginAccessErrorSbi: ' アクセスエラーが発生しました。リトライ',
  transferFeeNoMoneyRakuten:
    '振込手数料のポイント支払いについて、ご指定の充当ポイント数では普通預金残高が不足となります。',
  serviceUnavailableResona:
    'ご指定のサービスは、現在お取扱いできません。しばらくしてから再度ご利用ください｡',
  serviceUnavailableSaitamaResona:
    'ご指定のサービスは、現在お取扱いできません。しばらくしてから再度ご利用ください｡',
  notSupportedAuthenticationMethodAeon:
    '認証方式が確認番号表では振込取引をお受けできません。',
  passwordLockedAujibun: 'ログインパスワードがロックされています。',
  notApporvedTransactions:
    '承認待ち取引があるので、りそなグループアプリで承認操作を行ってください。',
  uninitializedTokenJapanPost:
    '先にワンタイムパスワードトークンを登録した後、もう一度お試しください。',
  accountBlockedSony:
    '現在ログインできませんので、しばらくたってから再度お試しください。',
  serviceUnavailableGMO: 'ただいま混み合っております。',
  loginMaintenanceFukuoka:
    '現在メンテナンスのため、サービスの取扱いを中止しております。恐れ入りますが、メンテナンス時間終了後に再度ご操作ください。',
  emailAddressRegistrationJapanPost:
    'ゆうちょダイレクトをご利用いただく際にはメールアドレスのご登録が必要です。',
  transactionPurposeDeclarationJapanPost:
    '先ず、ゆうちょ銀行のゆうちょダイレクトにログインし、「お客様情報申告」を行って下さい。その後改めて送金をお試しください。',
  maintenanceSuruga:
    'ただいま、システムメンテナンス中のため、サービスをご利用いただけません。定例メンテナンス日時：毎月第3日曜日22:00 ～ 翌 7:00',
  tooManyRecordedTrxResona:
    '普通預金の通帳への未記入取引が多いため、お取扱いできません。誠に恐れ入りますが、通帳にご記入のうえ、再度お手続きください。',
  transferCanceledFukuoka: '振込予約を取消しました。',
  applicationRequiredGMO:
    '総合振込をご利用いただくには、お申し込みが必要です。',
  transferCurrentlyUnavailableJapanPost:
    '現在、他金融機関あて振込はお取り扱いできません。',
  transferCurrentlyUnavailableSuruga:
    'ご利用時間を過ぎましたので、お取り引きを行うことができませんでした。',
  systemError: 'システムエラーが発生しました。',
  transferDisabledJapanPost:
    'ゆうちょ認証アプリを登録済みのお客さまが、お客さま番号・ログインパスワードを入力してログインした場合、送金等をご利用いただけません。',
  authAppNotUsedYetJapanPost:
    '以下のいずれかの理由により、お取り扱いできません。・Webによる再申し込みから24時間経過していない。・2022年1月4日（火）以降にゆうちょダイレクトを申し込み（書面による再申し込みを含む）、ゆうちょ認証アプリまたはトークンを利用していない。',
  reissuePasswordAeon:
    'パスワード等の入力相違が続いたため、現在イオン銀行ダイレクトにログインできない状態になっています。ご利用になるには、初回ログインパスワード再発行のお手続きが必要です。',
  unableToDeliverEmailSeven:
    'お客さまのお届け出の住所に郵便物をお届けすることができませんでした。',
  authAppLockedSeven:
    'スマートフォン認証アプリのプッシュ通知がロックされた状態になっております。お手数ですが、しばらくたってから再度ご利用ください。',
  authAppSuspendedSeven:
    '現在スマートフォン認証が一時停止中のため、新しい振込先へお振込みを行うことができません。',
  loggedOutSeven:
    '別ブラウザからログオン操作が行われたため、ログオフしました。',
  oneTimeKeyInvalidStateRakuten:
    'ワンタイムキーが無効な状態となっております。再度ご発行ください。',
  bankIsBusySmbc:
    '回線が混雑しております。恐れ入りますが、しばらくお待ちいただいてからアクセスしてください。',
  transferRestrictedRakuten:
    'お客さまの口座から楽天銀行口座宛の新規の振込を制限させていただいております。',
  suspendedAccountMizuho:
    'お客さまのインターネットバンキングのご利用を停止させていただきました。',
  blockedAccountAeon:
    'パスワード等の入力相違が続いたため、ロックされました。一定時間経過後にログインが可能になりますので、大変お手数をおかけいたしますが、しばらく経ってから再度お試しください。',
  serviceHoursChangedSMBC:
    'サービス時間帯が切り替わりました。月曜7:00~日曜21:00は、通常通りSMBCダイレクトのサービスがご利用いただけます（日曜21:00~月曜7:00は、一部サービスのみ）。',
  bankTransferErrorAujibun:
    '次の口座振替は、普通預金口座に必要な残高がなく、引落としできませんでした。引落としの完了確認は、入出金の明細をご確認ください。',
  passwordNotRegisteredAujibun: 'ログインパスワードが登録されていません。',
  oneTimeTokenExpiredRakuten:
    '認証失敗回数が規定上限を超えた為、ワンタイムキーが失効致しました。再度ご発行ください。',
  sameDayDeadlinePassedRakuten:
    '当日振込取引の受付時間を過ぎました。お手数ですがはじめからお取引手続きを進めてください。',
  illegalOperationRakuten: '不正な操作が行われました。',
  appAuthDisabledJapanPost:
    'ゆうちょ認証アプリで取引認証を「行わない」に設定されているため、送金等をご利用いただけません。',
  identityNotVerifiedYetJapanPost:
    'ゆうちょ認証アプリで書類によるご本人様確認を行っていないまたはご本人様確認書類の審査中のため、送金等がご利用いただけません。',
  registerSecretQuestionsResona: 'まずは秘密の質問と答えを登録してください。',
  registerSecretQuestionsSaitamaResona:
    'まずは秘密の質問と答えを登録してください。',
  bankErrorMizuho: '予期せぬエラーが発生したためログアウトしました。',
  notUsedForCertainAmountOfTimeSeven:
    '一定時間以上操作が行われなかった等の理由により、お取扱いできない状態になりましたので、ご利用を中断させていただきました。恐れ入りますが、再度ログオンしていただきますようお願いいたします。',
  transferDisabledSony: 'このお取り引きはご利用いただけません。',
  pinReissueRakuten: '暗証番号再発行のお手続き中です。',
  directPasswordLockedMufg:
    'ダイレクトパスワードにロックがかかっているため、ご利用いただけません。',
  pageCurrentlyUnavailableRakuten:
    'ご指定のページは、存在しないか、現在利用できません。',
  voiceCallVerificationFailedSMBC: '本人確認が正常に完了しませんでした',
  registerEmailSeven:
    'ダイレクトバンキングサービスをご利用いただくには、メールアドレスの登録が必要となります。',
  changeLoginPasswordSeven: 'ログオンパスワードの変更手続きのお願い',
  corporateCustomerBlockedGMO:
    '法人のお客さまはこの画面からはログインできません。',
  transactionRestrictionPayPay: 'ご指定の取引はお取扱いできません。',
  loggedOutAujibun:
    '別の端末からログインがあったか、または一定時間操作がないため、自動ログアウトしました。',
  transactionNotPossibleSony:
    '現在、お取り引きができません。しばらくたってから再度お手続きください。',
  loginMaintenanceRakuten:
    'ただいまメンテナンス中のため、ご利用いただくことができません。ご迷惑をおかけしますが、サービス再開後にご利用ください。',
  emailOtpDisabledJapanPost:
    '送金等のご利用には、ゆうちょ認証アプリ（無料）またはトークン（有料）のお申し込み・ご登録が必要です。 メールワンタイムパスワードによる送金時認証は、2023年7月4日（火）に廃止いたしました。',
  maintenanceSmbc: '現在システムメンテナンスのため、サービスを停止しています',
  transfersTemporaryRestrictedAujibun:
    '現在、他金融機関宛てのお振込取引を一時的に制限しております。',
  transferCannotBeHandledMizuho:
    'ご指定の口座への振込・振替はお取り扱いできません。',
  noPhoneRegisteredSmbc: '追加の本人確認が可能な電話番号の登録がございません。',
  transactionNotOperatedCorrectlyAeon:
    '正しく操作されていないため、お取引をお受けできません。最初からやり直してください。',
  destinationAccountErrorResona: '振込先の口座情報が確認できませんでした。',
  destinationAccountErrorSaitamaResona:
    '振込先の口座情報が確認できませんでした。',
  destinationAccountErrorFukuoka: '振込先口座が存在しませんでした。',
  notADepositableAccountRakuten: 'ご指定の口座は入金可能な口座ではありません。',
  inactiveTokenSMBC:
    '不正送金被害にともなうセキュリティ強化のため、ワンタイムパスワードの有効化（利用登録）後、3時間を経過するまで本振込は受付できません。',
  semiRedirectLackingManualOption: 'あなたの取引は完了できませんでした。',
  wrongRecipient: 'ご指定いただいた口座番号が見つかりません。',
  unsettledPendings: '未決済の振込が以下の通り保留中となっています。',
  phoneVerificationErrorPayPay:
    'ご登録電話番号以外から電話をかけているご登録電話番号はお客さま情報変更より確認・変更が可能です。',
  incorrectAccountNumber: '「送金先口座番号」のご指定が間違っています。',
  cannotConfirmQrSMBC:
    'SMBCセーフティパスをご登録いただいた端末での承認が確認できませんでした。最初からお手続きください。',
  unableToConfirmOnDeviceSMBC:
    'SMBCセーフティパスをご登録いただいた端末での承認が確認できません。案内に沿ってお手続きの上、「承認操作を完了しました」を押下してください。登録時の端末をお持ちでない場合は、三井住友銀行アプリよりSMBCセーフティパスを解除してください。くわしくは',
  couldNotConfirmTransactionMufg: '操作が確認できませんでした。',
  inactiveSafetyPassOrOTP:
    'お振込にあたっては、SMBCセーフティパスの登録、またはワンタイムパスワードの有効化 を事前に行って',
  previousTransactionNotCompletedAeon:
    '前回のお取引が正常に完了しなかった可能性があります。お取引履歴照会にて、お取引内容と結果をご確認ください。',
  resetPasswordSuruga:
    'ご入力いただいた内容ではお取り扱いができない状態になっています。パスワードを再設定してください。',
  loggedFromOtherDeviceSony:
    'また、他の端末でログインされた場合もこの画面が表示されます。',
  maintenanceSeven:
    '申し訳ございません。現在ご利用いただけない状態となっております。',
  customerInfoConfirmation:
    'PAYPAY銀行のサイトへログインして下さい。お客さまの口座は、お客さま情報・取引目的などの確認が必要となっています。回答フォームご記入後、再度こちらのページから送金をお試しください。',
  transactionSuspendedPayPay: '銀行の決定により取引は停止されました。',
  transfersCurrentlyDisabledMizuho:
    '現在、お客さまは、お振込サービスをご利用いただけません。',
  loggedInFromAnotherBrowserResona:
    '別ブラウザからログイン操作が行われました。恐れ入りますが、再度ログインをしてください。',
  smartphoneAuthenticationSettingCanceledResona:
    'スマホ認証の設定が解除されています。りそなグループアプリをセットアップし、マイゲートと連携後再度お手続きください。',
  changePasswordResona: 'ログインパスワードの変更',
  registerEmailResona: '連絡先メールアドレスを登録します。',
  authenticationNotCompletedResona:
    '認証方法の設定が完了していないためお取扱いできません。「その他サービス」＞「パスワード関連変更」よりスマホ認証もしくはハードウェアトークンへの切替完了後に再度お手続きください。',
  authenticationNotCompletedSaitamaResona:
    '認証方法の設定が完了していないためお取扱いできません。「その他サービス」＞「パスワード関連変更」よりスマホ認証もしくはハードウェアトークンへの切替完了後に再度お手続きください。',
  transactionsNotAcceptedTodayGMO:
    'ただいま、本日付のお振込は受け付けできません。',
  smartphoneAuthenticationRequiredResona:
    'マイゲートで振込、ネット振込サービス、ペイジー料金払込、Web口座振替等をご利用いただくにはスマホ認証またはハードウェアトークンでの認証が必須です。ご利用のお客さまはマイゲート内「その他サービス」＞「パスワード関連変更」からスマホ認証またはハードウェアトークンへのお切替手続をお願いします。',
  smartphoneAuthenticationRequiredSaitamaResona:
    'マイゲートで振込、ネット振込サービス、ペイジー料金払込、Web口座振替等をご利用いただくにはスマホ認証またはハードウェアトークンでの認証が必須です。ご利用のお客さまはマイゲート内「その他サービス」＞「パスワード関連変更」からスマホ認証またはハードウェアトークンへのお切替手続をお願いします。',
  oldAuthenticationMethodUnavailableResona:
    'スマホ認証、ハードウェアトークン、届出電話番号による認証等がご利用できなくなりました。',
  oldAuthenticationMethodUnavailableSaitamaResona:
    'スマホ認証、ハードウェアトークン、届出電話番号による認証等がご利用できなくなりました。',
  accountNotConfirmedMUFG: 'ご指定の口座が確認できませんでした。',
  corporateAccountUnavailable: 'あなたの取引は完了できませんでした。',
  depositAccountNotRegisteredSMBC:
    '振替できる預金口座（普通預金・貯蓄預金・当座預金）が、サービス利用口座に登録されていません。',
  parallelBankLoginAujibun:
    '別の端末からのログインがあったか、または一定時間動作がないため、自動ログアウトしました。',
  customerUsingPaperCardMizuho:
    'みずほダイレクトアプリで第2暗証番号を発行するご利用カード（アプリ版）へ切替のうえ、お取引いただきますようお願いいたします。',
  outsideOperatingHoursMUFG:
    '振込操作の設定時間外のため、ただいまの時間はお取り扱いできません。振込操作時間の変更は、トップページの【すべてのメニューを見る】－【即時振込の登録・解除】からお手続きできます。',
  maintenanceSony: 'ただいま、システムメンテナンスのためサービス停止中です。',
  otpSuspendedMUFG:
    '現在、お客さまのワンタイムパスワードの利用を停止しています。',
  CustomerInformationVerificationAujibun:
    '現在の登録内容をご確認いただき、最新の情報へ変更をお願いいたします。',
  functionNotAvailableForAccount:
    'ご利用の口座では当機能はご利用いただけません。',
  myGateRestrictionTransaction:
    '誠に恐れ入りますが、マイゲートではご利用開始後の期間や振込金額、振込回数などにより、お取引を制限させていただいております',
  systemErrorOccurred:
    'システムエラーが発生しました。再度ログインをお試しください。',
  incorrectBankInformationRakuten: 'ご指定の銀行情報は存在しません。',
  kanaError: 'カタカナでご記入ください',
  loginOTPAppRegistrationNotCompleted:
    'ワンタイムパスワード（アプリ）の利用登録が完了していません。',
  upperLimitExceededMUFG:
    'ワンタイムパスワード（アプリ）の利用登録を電話（自動音声）によるご本人さま確認で実施されているため、当行の設定する上限額を超える振込等が出来ない状態です。',
  temporaryPinPayPay:
    'お客さまの暗証番号は仮暗証番号です。暗証番号の変更をお願いします。 銀行に直接ログインしてください',
  cannotContinueTransactionRakuten:
    'お取引を続けることができません。以下の点をご確認いただき、再度ログインのうえ、お取引ください。',
  operationDidNotCompleteSuccessfullySMBC: '処理が正常に完了しませんでした',
};

const errorStringsEN = {
  incorrectCredentials: 'Login information is incorrect',
  incorrectBranch: 'Branch number is incorrect',
  incorrectAccount: 'Account number is incorrect',
  incorrectPassword: 'The password is in correct',
  invalidEmailCode: 'Invalid email verification code',
  invalidOtpCode: 'Invalid one-time password (OTP) code',
  hiddenCharRetry: 'There is an error in the one-time password entered',
  invalidData: 'Invalid data. Retry',
  invalidSMSCode: 'Invalid sms code',
  noMoney: 'Insufficient funds',
  loginBlockedMUFG: 'You cannot use it because your direct password is locked',
  loginAccountBlocked: 'It looks like your account has been blocked',
  loginAccountInactive:
    "Unable to log in because the bank's designated time has passed",
  loginAccountNotUsed:
    'Since this account has not had any transactions for a long time, it will be managed as a dormant deposit and transactions will be temporarily suspended based on the "Rakuten Bank Account Transaction Regulations."',
  accountTemporaryBlocked:
    'Your account has been temporarily blocked. Try later',
  insufficientBalance: 'Insufficient balance',
  serviceUnavailable: 'Service is temporarily unavailable',
  // internalError: '内部エラー',
  internalError: 'Your transaction could not be completed',
  TokenLocked: 'Your token is locked',
  WrongAuthData: 'The authentication information you entered is incorrect',
  AccountBlocked: 'Account is blocked',
  limitExceeded: 'Daily transaction limit exceeded',
  minTransferLimit: 'The remittance amount is less than the minimum amount',
  maxTransferLimit: 'The remittance amount exceeds the maximum amount',
  onlyUSDTransfers:
    'Currently, only US dollar-denominated transactions are possible',
  multiTransactionsMufg:
    'We have suspended the transaction for security reasons because we were unable to confirm any operations for a certain period of time. Please log in again to make a transaction',
  incorrectLength: 'The number of characters is different',
  onlyNumbers: 'Only numbers can be entered',
  fieldRequired: 'Required fields',
  timeout: 'Timeout',
  accountLimitExceeded: 'The transaction amount exceeds the daily usage limit',
  accountAmountLimitExceeded:
    'The transaction amount exceeds your daily limit.',
  dailyLimitWillBeExceeded:
    'The amount you entered cannot be transferred because it exceeds the daily transfer limit',
  existTransactionToApprove:
    'There are transactions pending approval. Only one transaction can be reserved for smartphone authentication at a time',
  blockedInternetBanking: 'Internet banking lock is set.',
  lockedConfirmationNumber: 'Confirmation number is locked.',
  loginServiceUnavailable: 'This service is not available at this time',
  ipRestriction:
    'The specified transaction cannot be performed because the registered usage environment is different',
  smbcIncorrectContact:
    'There is an error in the subscriber number (example) 12345-67890',
  cantCompleteTrx:
    'The transaction could not be completed due to an error from the bank',
  accountUninitializedSMBC: 'Initial setting of one-time password is required',
  timeoutOtpPayPay:
    'This connection has been disconnected due to a timeout or an overwrite login. Please try again from the beginning',
  timeoutOtpMufg:
    'We have suspended the transaction for security reasons because we were unable to confirm any operations for a certain period of time',
  timeoutEmailCodeGMO:
    'The URL has expired. Please complete the procedure from the beginning',
  timeoutAppAuthGMO:
    'We were unable to authenticate your transaction. Please check the latest status. Please check the transfer status on the transfer status inquiry page',
  answersLimitRakuten:
    'Authentication information has been entered incorrectly more than the specified number of times. Login will be temporarily restricted',
  answersLimitGMO:
    'Transactions will be restricted because transaction authentication has failed a certain number of times. Please wait as it will be canceled the next day',
  incorrectOtpLimitJapanPost:
    'The specified "one-time password" is incorrect. I have logged out because a certain number of errors have occurred',
  accountUninitializedMUFG:
    'Transactions are not possible because the Mitsubishi UFJ Direct login password has not been registered',
  otpInactiveMUFG:
    'This transaction cannot be used because you have not registered to use a one-time password',
  serviceUnavailableMufg: 'Not available',
  incorrectTelephoneFormat:
    'Phone number format is incorrect (example) 1-123-4567-8901',
  appAuthRetry: 'Your request was not accepted correctly',
  goToBankSite: "Visit your bank's webpage",
  loginAccountUninitializedRakuten:
    'Please cancel the transfer, log in to Rakuten Bank Online Bank again, and enter your customer card information. Please enter your customer card and try the transfer again',
  tryLaterRakuten:
    'Access is currently very busy. We apologize for the inconvenience, but please try again later',
  loginAttemptsExceededRakuten:
    'Authentication information has been entered incorrectly more than the specified number of times. Login will be temporarily restricted',
  transfersDisabledResona:
    'Online money transfers are not possible from your account',
  loginReset:
    'Please log in to Mitsubishi UFJ Bank Online Bank again and reset your login password. Please try the transfer again after resetting your login password',
  loginUnavailableResona: 'Could not log in',
  transferPasswordError: 'Transaction password is incorrect',
  transferCanceled: 'Transaction canceled',
  loginAuthNotProvidedSbi: 'Your request was not accepted correctly',
  canceledByUserGMO: 'Your transaction has been deauthorized',
  loginMaintenancePayPay:
    'This site is currently undergoing maintenance and cannot be used. We apologize for the inconvenience, but please try again after the service is resumed',
  noQrCodeConfirmation:
    'Please complete the authentication using the "Japan Post Authentication App".',
  transferUnavailableJapanPost: 'Log in using biometrics',
  loginServerCrowdedRakuten:
    'Access is currently very busy. We apologize for the inconvenience, but please try again later',
  nightErrorRakuten:
    'Transactions are not possible as the transaction is being processed at night. Please wait for a while and try again',
  loginMaintenanceResona:
    'The service is currently suspended due to system maintenance',
  appNotActivatedJapanPost:
    'Biometric authentication was not possible. If you have not registered the Japan Post Authentication App, or if your customer number has changed due to reapplying for Japan Post Direct or registering your account, please register the Japan Post Authentication App again and use it again',
  maintenanceJapanPost:
    'We are currently unable to process your request as it is outside of our operating hours',
  invalidPhoneNumber: 'Invalid client phone number code',
  dailyLimitZero:
    'The daily remittance limit on Japan Post Direct is set to 0 yen, so you cannot remit money',
  transfersDisabledSaitamaResona:
    'あなたのアカウントからはオンライン送金は出来ません。',
  transferUnavailableResona:
    'Online money transfers are not possible from your account',
  authentictionNoMoreAvailableSaitama:
    'The hardware token or smartphone authentication you are currently using is no longer usable. For more information, please contact the My Gate Support Center',
  registerNewCreditCardSeven:
    'Your cash card will be reissued, so please complete the registration process to start using your new cash card',
  otherSideMaintenancePayPay:
    'There is a possibility that the transfer cannot be made on the specified transfer date due to maintenance etc. at the recipient financial institution',
  automaticLogoutMizuho:
    'You were automatically logged out because there was another login with the same customer number',
  incorrectRecipientNameMizuho:
    "The method of entering the recipient's name for the transfer is incorrect",
  newPasswordNeededRakuten: 'Please set a new login password',
  qrCommunicationError:
    'Due to a communication error, etc., we were unable to process your request. This error will also appear if you scan the same QR code more than once',
  transactionSuspended: 'Your bank has suspended your transaction',
  dailyCumulativeLimitExceededJapanPost:
    "As today's cumulative remittance amount exceeds 50,000 yen, you cannot use instant remittance using your email one-time password",
  notWithdrawalAccount:
    'The specified account is not an account that allows withdrawals',
  appCanceledResona:
    'Transaction authorization was not confirmed in the application',
  appCanceledSaitamaResona:
    'Transaction authorization was not confirmed in the application',
  loginMaintenanceMufg:
    'This product is currently unavailable due to regular or temporary maintenance',
  emailCodeExpiredMufg: 'Your one-time password has expired',
  secretUninitializedRakuten:
    'This online account has not been initialized. After completing the account setup on the Rakuten Bank web page, please try remitting money from this page again',
  maintenanceSbi:
    'This product is currently unavailable due to regular or temporary maintenance',
  dailyLimitResona: 'The transfer amount exceeded the daily transfer limit',
  dailyLimitSaitamaResona:
    'The transfer amount exceeded the daily transfer limit',
  withdrawalAccountUnavailableSMBC:
    'The withdrawal account you specified is currently unavailable',
  maintenanceAujibun: 'Currently unavailable due to system maintenance',
  appAuthNotSetSaitamaResona:
    'Please set up the Resona Group app, link it with MyGate, and then try the procedure again',
  appAuthNotSetResona:
    'Please set up the Resona Group app, link it with MyGate, and then try the procedure again',
  tokenExpired:
    'The software token you are using has expired and cannot be used. Please apply for the use of the new hardware token or smartphone authentication from "Various changes/procedures"',
  transferLimitPerTransactionAeon:
    'The amount exceeds the limit for one transfer',
  transferLimitPerDayAeon: 'The daily transfer limit has been exceeded',
  loginMaintenanceAeon: 'Currently unavailable due to system maintenance',
  registerOneTimePasswordResona:
    'From January 16, 2017, a one-time password is required to use MyGate for transfers, online transfer services, and Payeasy fee payments. Additionally, from September 11, 2017, a one-time password is required for some customers using online account transfer',
  registerOneTimePasswordSaitamaResona:
    'From January 16, 2017, a one-time password is required to use MyGate for transfers, online transfer services, and Payeasy fee payments. Additionally, from September 11, 2017, a one-time password is required for some customers using online account transfer',
  confirmationPassLockedResona: 'Confirmation password locked out',
  confirmationPassLockedSaitamaResona: 'Confirmation password locked out',
  trxInProgressFukuoka:
    'The previous transaction may not have been completed correctly. Please check the transaction details and try again. (Top page → Menu list → Transaction history inquiry)',
  transferLimitPerDaySony: 'The daily transfer limit has been exceeded',
  transactionErrorSony:
    'An error occurred during the procedure. The current procedure may not have been completed',
  transferRejectedSony:
    'Transaction authorization was not confirmed in the application',
  systemErrorSBI:
    'A system error has occurred. The transaction in progress may not have been completed. If the transaction has not been completed, please complete the transaction again',
  uninitializedOtpFuku:
    'The one-time password required for transactions cannot be used because registration has not been completed',
  hiraganaOnly: 'Please enter in "Hiragana"',
  alreadyLoggedInSuruga:
    "We have logged out because we have repeatedly verified the recipient's account",
  dailyLimitSuruga:
    'The amount you entered cannot be transferred because it exceeds the daily transfer limit.',
  invalidOperationFukuoka:
    'An invalid operation was performed. Please try again from the login screen',
  loginAccessErrorSbi: 'An access error has occurred. Retry',
  transferFeeNoMoneyRakuten:
    'Regarding the point payment for transfer fees, there will not be enough points in your savings account to use the specified number of points',
  serviceUnavailableResona:
    'The specified service is currently unavailable. Please try again later',
  serviceUnavailableSaitamaResona:
    'The specified service is currently unavailable. Please try again later',
  notSupportedAuthenticationMethodAeon:
    'Transfer transactions cannot be accepted if the authentication method is the confirmation number table',
  passwordLockedAujibun: 'Your login password is locked',
  notApporvedTransactions:
    'There is a transaction waiting for approval, so please perform the approval operation on the Resona Group app',
  uninitializedTokenJapanPost:
    'Please register your one-time password token first and then try again',
  accountBlockedSony:
    'You are currently unable to log in. Please try again later',
  serviceUnavailableGMO: "It's crowded right now",
  loginMaintenanceFukuoka:
    'The service is currently suspended due to maintenance. We apologize for the inconvenience, but please try again after the maintenance period ends',
  emailAddressRegistrationJapanPost:
    'When using Japan Post Direct, you must register your email address',
  transactionPurposeDeclarationJapanPost:
    'First, log in to Japan Post Bank\'s Japan Post Direct and complete the "Customer Information Declaration." After that, please try sending the money again',
  maintenanceSuruga:
    'The service is currently unavailable due to system maintenance. Regular maintenance date and time: 3rd Sunday of every month from 22:00 to 7:00 the next day',
  tooManyRecordedTrxResona:
    'Due to the large number of transactions that are not entered in the ordinary deposit passbook, we are unable to handle this transaction. We apologize for the inconvenience, but please fill out your bankbook and try the procedure again',
  transferCanceledFukuoka: 'I canceled my transfer reservation',
  applicationRequiredGMO: 'To use general transfer, you need to apply',
  transferCurrentlyUnavailableJapanPost:
    'We currently cannot accept transfers to other financial institutions',
  transferCurrentlyUnavailableSuruga:
    'The transaction could not be completed because the usage time has passed',
  systemError: 'A system error has occurred',
  transferDisabledJapanPost:
    'If a customer who has registered the Japan Post Authentication app logs in by entering their customer number and login password, they will not be able to make remittances, etc',
  authAppNotUsedYetJapanPost:
    'We cannot handle this item for any of the following reasons.・24 hours have not passed since you re-applied online.・You have not applied for Japan Post Direct (including reapplying in writing) or used the Japan Post Authentication app or token after January 4, 2022 (Tuesday)',
  reissuePasswordAeon:
    'Currently, I am unable to log in to AEON Bank Direct due to a series of incorrect password entries. To use this service, you will need to complete the procedure to have your first login password reissued',
  unableToDeliverEmailSeven:
    "The mail could not be delivered to the customer's registered address",
  authAppLockedSeven:
    'Push notifications for the smartphone authentication app are now locked. We apologize for the inconvenience, but please try again later',
  authAppSuspendedSeven:
    'As smartphone authentication is currently suspended, we are unable to transfer money to a new recipient',
  loggedOutSeven:
    'You have been logged off because you attempted to log on from another browser',
  oneTimeKeyInvalidStateRakuten: 'One-time key is disabled. Please reissue',
  bankIsBusySmbc:
    'The line is congested. We apologize for the inconvenience, but please wait for a while before accessing',
  transferRestrictedRakuten:
    'We are restricting new transfers from your account to your Rakuten Bank account',
  suspendedAccountMizuho: 'We have suspended your use of Internet banking',
  blockedAccountAeon:
    'Your account has been locked due to repeated incorrect password entries. You will be able to log in after a certain amount of time has passed. We apologize for the inconvenience, but please try again later',
  serviceHoursChangedSMBC:
    'Service hours have changed. SMBC Direct services are available as usual from Monday 7:00 to Sunday 21:00 (only some services are available from Sunday 21:00 to Monday 7:00)',
  bankTransferErrorAujibun:
    'The following account transfer could not be made because my savings account did not have the required balance. Please check the deposit/withdrawal details to confirm the completion of the withdrawal',
  passwordNotRegisteredAujibun: 'No login password has been registered',
  oneTimeTokenExpiredRakuten:
    'The one-time key has expired because the number of authentication failures has exceeded the specified upper limit. Please reissue',
  sameDayDeadlinePassedRakuten:
    'The acceptance time for same-day transfer transactions has passed. We apologize for the inconvenience, but please proceed with the transaction procedure from the beginning',
  illegalOperationRakuten: 'An illegal operation has occurred',
  appAuthDisabledJapanPost:
    'Since transaction authentication is set to "no" in the Japan Post Authentication app, remittances etc. cannot be used',
  identityNotVerifiedYetJapanPost:
    'You cannot use remittances, etc. because your identity has not been verified using documents using the Japan Post Authentication app, or your identity verification documents are currently being reviewed',
  registerSecretQuestionsResona:
    'First, please register your secret question and answer.',
  registerSecretQuestionsSaitamaResona:
    'First, please register your secret question and answer.',
  bankErrorMizuho:
    'You have been logged out because an unexpected error occurred',
  notUsedForCertainAmountOfTimeSeven:
    'Due to reasons such as inactivity for a certain period of time, the service has become unavailable, so we have suspended its use. We apologize for the inconvenience, but please log on again',
  transferDisabledSony: 'This deal is no longer available',
  pinReissueRakuten: 'We are in the process of reissuing your PIN',
  directPasswordLockedMufg:
    'You cannot use it because your direct password is locked',
  pageCurrentlyUnavailableRakuten:
    'The page you specified does not exist or is currently unavailable',
  voiceCallVerificationFailedSMBC:
    'Identity verification was not completed successfully',
  registerEmailSeven:
    'To use the Direct Banking Service, you will need to register your email address.',
  changeLoginPasswordSeven: 'Request to change logon password',
  corporateCustomerBlockedGMO:
    'Corporate customers cannot log in from this screen',
  transactionRestrictionPayPay: 'The specified transaction cannot be processed',
  loggedOutAujibun:
    'You were automatically logged out because you logged in from another device or there was no activity for a certain period of time',
  transactionNotPossibleSony:
    'Transactions are currently unavailable. Please try again after a while',
  loginMaintenanceRakuten:
    'This site is currently undergoing maintenance and cannot be used. We apologize for the inconvenience, but please try again after the service is resumed',
  emailOtpDisabledJapanPost:
    'To use the Japan Post Authentication app (free) or token (paid), you will need to apply for and register for a Japan Post Authentication app (free) or a token (paid) to use for remittances, etc. Authentication for remittance using email one-time password will be discontinued on July 4, 2023 (Tuesday)',
  maintenanceSmbc:
    'The service is currently suspended due to system maintenance',
  transfersTemporaryRestrictedAujibun:
    'We are currently temporarily restricting transfer transactions to other financial institutions',
  transferCannotBeHandledMizuho:
    'We cannot accept transfers or transfers to your designated account',
  noPhoneRegisteredSmbc:
    'There is no registered phone number that can be used for additional identity verification',
  transactionNotOperatedCorrectlyAeon:
    'There is no registered phone number that can be used for additional identity verification',
  destinationAccountErrorResona:
    'The account information for the transfer destination could not be confirmed',
  destinationAccountErrorSaitamaResona:
    'The account information for the transfer destination could not be confirmed',
  destinationAccountErrorFukuoka: 'The destination account did not exist',
  notADepositableAccountRakuten:
    'The specified account is not an account that can be deposited',
  inactiveTokenSMBC:
    'In order to strengthen security in response to damage caused by fraudulent remittances, we will not be able to accept regular remittances until 3 hours have passed after the one-time password has been activated (registered for use)',
  semiRedirectLackingManualOption: 'Your transaction could not be completed',
  wrongRecipient: "The recipient's account number could not be found.",
  unsettledPendings:
    'Please note, that you have outstanding, unsettled pending trxs',
  phoneVerificationErrorPayPay:
    'A call is being made from a phone number other than your registered one. You can check and change your registered phone number by changing your customer information .',
  incorrectAccountNumber:
    'The specified "remittance account number" is incorrect.',
  cannotConfirmQrSMBC:
    'Approval could not be confirmed on the device on which you registered SMBC Safety Pass. Please complete the procedure from the beginning.',
  unableToConfirmOnDeviceSMBC:
    'We are unable to confirm authorization on the device on which you registered SMBC Safety Pass. Please follow the instructions and click "Approval operation completed". If you do not have the device you registered with, please cancel SMBC Safety Pass from the Sumitomo Mitsui Banking Corporation app.',
  couldNotConfirmTransactionMufg: 'The operation could not be confirmed.',
  inactiveSafetyPassOrOTP:
    'Transfer suspended, unregistered SMBC Safety Pass or inactive one-time passwords.',
  previousTransactionNotCompletedAeon:
    'The previous transaction may not have completed successfully. Please check transaction details and results by checking transaction history.',
  resetPasswordSuruga:
    'The information you entered cannot be processed. Please reset your password.',
  loggedFromOtherDeviceSony:
    'This screen will also be displayed if you log in from another device.',
  maintenanceSeven:
    "I'm sorry. It is currently unavailable. Please try again after a while.",
  customerInfoConfirmation:
    'Please log in to the PAYPAY Bank website. For customer accounts, confirmation of customer information, transaction purpose, etc. is required. After filling out the response form, please try remitting money from this page again.',
  transactionSuspendedPayPay:
    "The transaction has been suspended due to bank's decision.",
  transfersCurrentlyDisabledMizuho:
    'Currently customers cannot use the money transfer service.',
  loggedInFromAnotherBrowserResona:
    'A login operation was performed from another browser.',
  smartphoneAuthenticationSettingCanceledResona:
    'The smartphone authentication setting has been canceled. The user must configure the Resona Group application, connect it to My Gate.',
  changePasswordResona: 'Change your login password',
  registerEmailResona: 'Please register your contact email address.',
  authenticationNotCompletedResona:
    'This cannot be done because the authentication method has not been set up. Please go to "Other Services" > "Password Changes" and switch to smartphone authentication or hardware token and then try again.',
  authenticationNotCompletedSaitamaResona:
    'This cannot be done because the authentication method has not been set up. Please go to "Other Services" > "Password Changes" and switch to smartphone authentication or hardware token and then try again.',
  transactionsNotAcceptedTodayGMO:
    'We are currently unable to accept any transfers for today.',
  smartphoneAuthenticationRequiredResona:
    'To use MyGate for transfers, online transfer services, Pay-easy fee payments, web account transfers, etc., authentication with a smartphone or hardware token is required. Customers who use this service should go to "Other Services" > "Password Changes" in MyGate and complete the procedure to switch to smartphone authentication or a hardware token.',
  smartphoneAuthenticationRequiredSaitamaResona:
    'To use MyGate for transfers, online transfer services, Pay-easy fee payments, web account transfers, etc., authentication with a smartphone or hardware token is required. Customers who use this service should go to "Other Services" > "Password Changes" in MyGate and complete the procedure to switch to smartphone authentication or a hardware token.',
  oldAuthenticationMethodUnavailableResona:
    'Smartphone authentication, hardware tokens, and authentication using registered phone numbers are no longer available.',
  oldAuthenticationMethodUnavailableSaitamaResona:
    'Smartphone authentication, hardware tokens, and authentication using registered phone numbers are no longer available.',
  accountNotConfirmedMUFG: 'We were unable to verify your specified account.',
  corporateAccountUnavailable:
    'The account you want to transfer to does not exist. Check the account number.',
  depositAccountNotRegisteredSMBC:
    'The deposit account to which funds can be transferred (regular deposit, savings deposit, current account) is not registered as an account to be used for the service.',
  parallelBankLoginAujibun:
    'You were automatically logged out because you logged in from another device or there was no activity for a certain period of time.',
  customerUsingPaperCardMizuho:
    'Please switch to a card (app version) that allows you to issue a second PIN number via the Mizuho Direct app before making your transaction.',
  outsideOperatingHoursMUFG:
    'Transfers cannot be processed at this time as they are outside of the set operating hours. Transfers cannot be made during this time as they are outside the established transfer operation hours.To change the transfer operation time, the customer must go to [View all menus]/[すべてのメニューを見る] on the home page and select [Register/Cancel instant transfers]/[即時振込の登録・解除].',
  transactionNotFoundInHistoryPayPay: 'Transaction not found in history list',
  maintenanceSony:
    'The service is currently suspended due to system maintenance.',
  otpSuspendedMUFG: 'One-time password is currently suspended.',
  CustomerInformationVerificationAujibun:
    'Please check your current registration details and update them to the latest information.',
  functionNotAvailableForAccount:
    'This feature is not available for your account.',
  myGateRestrictionTransaction:
    'We apologize for the inconvenience, but MyGate places restrictions on transactions based on the period since you began using the service, the transfer amount, the number of transfers, etc.',
  systemErrorOccurred: 'A system error occurred. Please try logging in again.',
  incorrectBankInformationRakuten:
    'The bank information you specified does not exist.',
  invalidRecipientNameFormat: 'Invalid recipient name string format',
  loginOTPAppRegistrationNotCompleted:
    'Registration for the one-time password (app) has not been completed.',
  upperLimitExceededMUFG:
    'Since the registration for the one-time password (app) is done by verifying your identity over the phone (automated voice), you are currently unable to make transfers etc. that exceed the upper limit set by our bank.',
  temporaryPinPayPay:
    'Your PIN is a temporary PIN. Please change it. Please log in to the bank directly.',
  cannotContinueTransactionRakuten:
    'You will not be able to continue trading. Please check the following points, log in again, and then continue trading.',
  operationDidNotCompleteSuccessfullySMBC:
    'The operation did not complete successfully',
  requestFromExpiredPageSMBC:
    'Transition error. The request was made from an expired page.',
};

const errors = {
  Timeout: {
    type: 'general',
    message: errorStrings.timeout,
    messageEN: errorStringsEN.timeout,
  },
  loginRetry: {
    type: 'login',
    message: errorStrings.incorrectCredentials,
    messageEN: errorStringsEN.incorrectCredentials,
  },
  loginReset: {
    type: 'general',
    message: errorStrings.loginReset,
    messageEN: errorStringsEN.loginReset,
  }, // TODO change string
  loginUnknown: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  TokenLocked: {
    type: 'general',
    message: errorStrings.TokenLocked,
    messageEN: errorStringsEN.TokenLocked,
  },
  AccountBlocked: {
    type: 'general',
    message: errorStrings.AccountBlocked,
    messageEN: errorStringsEN.AccountBlocked,
  },
  Unknown: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  InternalError: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  otpRetry: {
    type: 'verification',
    message: errorStrings.invalidOtpCode,
    messageEN: errorStringsEN.invalidOtpCode,
  },
  hiddenCharRetry: {
    type: 'verification',
    message: errorStrings.invalidOtpCode,
    messageEN: errorStringsEN.invalidOtpCode,
  },
  smsCodeRetry: {
    type: 'verification',
    message: errorStrings.invalidSMSCode,
    messageEN: errorStringsEN.invalidSMSCode,
  },
  setRetry: {
    type: 'verification',
    message: errorStrings.invalidData,
    messageEN: errorStringsEN.invalidData,
  },
  NoMoney: {
    type: 'general',
    message: errorStrings.noMoney,
    messageEN: errorStringsEN.noMoney,
  },
  loginNoMoney: {
    type: 'general',
    message: errorStrings.noMoney,
    messageEN: errorStringsEN.noMoney,
  },
  loginAccountInactive: {
    type: 'general',
    message: errorStrings.loginAccountInactive,
    messageEN: errorStringsEN.loginAccountInactive,
  },
  loginAccountNotUsed: {
    type: 'general',
    message: errorStrings.loginAccountNotUsed,
    messageEN: errorStringsEN.loginAccountNotUsed,
  },
  loginAccountBlocked: {
    type: 'general',
    message: errorStrings.loginAccountBlocked,
    messageEN: errorStringsEN.loginAccountBlocked,
  },
  loginBlockedMUFG: {
    type: 'general',
    message: errorStrings.loginBlockedMUFG,
    messageEN: errorStringsEN.loginBlockedMUFG,
  },
  loginServiceUnavailable: {
    type: 'general',
    message: errorStrings.loginServiceUnavailable,
    messageEN: errorStringsEN.loginServiceUnavailable,
  },
  loginAccountTemporaryBlocked: {
    type: 'general',
    message: errorStrings.accountTemporaryBlocked,
    messageEN: errorStringsEN.accountTemporaryBlocked,
  },
  AccountTemporaryBlocked: {
    type: 'general',
    message: errorStrings.accountTemporaryBlocked,
    messageEN: errorStringsEN.accountTemporaryBlocked,
  },
  emailCodeRetry: {
    type: 'verification',
    message: errorStrings.invalidEmailCode,
    messageEN: errorStringsEN.invalidEmailCode,
  },
  WrongAuthData: {
    type: 'general',
    message: errorStrings.WrongAuthData,
    messageEN: errorStringsEN.WrongAuthData,
  },
  dateRetry: {
    type: 'verification',
    message: errorStrings.invalidData,
    messageEN: errorStringsEN.invalidData,
  },
  customRetry: {
    type: 'verification',
    message: errorStrings.invalidData,
    messageEN: errorStringsEN.invalidData,
  },
  connectionLost: {
    type: 'general',
    message: errorStrings.serviceUnavailable,
    messageEN: errorStringsEN.serviceUnavailable,
  },
  LimitExceeded: {
    type: 'general',
    message: errorStrings.limitExceeded,
    messageEN: errorStringsEN.limitExceeded,
  },
  MinTransferLimit: {
    type: 'general',
    message: errorStrings.minTransferLimit,
    messageEN: errorStringsEN.minTransferLimit,
  },
  MaxTransferLimit: {
    type: 'general',
    message: errorStrings.maxTransferLimit,
    messageEN: errorStringsEN.maxTransferLimit,
  },
  OnlyUSDTransfers: {
    type: 'general',
    message: errorStrings.onlyUSDTransfers,
    messageEN: errorStringsEN.onlyUSDTransfers,
  },
  MultiTransactionsMufg: {
    type: 'general',
    message: errorStrings.multiTransactionsMufg,
    messageEN: errorStringsEN.multiTransactionsMufg,
  },
  AccountLimitExceeded: {
    type: 'general',
    message: errorStrings.accountLimitExceeded,
    messageEN: errorStringsEN.accountLimitExceeded,
  },
  AccountAmountLimitExceeded: {
    type: 'general',
    message: errorStrings.accountAmountLimitExceeded,
    messageEN: errorStringsEN.accountAmountLimitExceeded,
  },
  DailyLimitWillBeExceeded: {
    type: 'general',
    message: errorStrings.dailyLimitWillBeExceeded,
    messageEN: errorStringsEN.dailyLimitWillBeExceeded,
  },
  ExistTransactionToApprove: {
    type: 'general',
    message: errorStrings.existTransactionToApprove,
    messageEN: errorStringsEN.existTransactionToApprove,
  },
  loginBlockedInternetBanking: {
    type: 'general',
    message: errorStrings.blockedInternetBanking,
    messageEN: errorStringsEN.blockedInternetBanking,
  },
  BlockedInternetBanking: {
    type: 'general',
    message: errorStrings.blockedInternetBanking,
    messageEN: errorStringsEN.blockedInternetBanking,
  },
  LockedConfirmationNumber: {
    type: 'general',
    message: errorStrings.lockedConfirmationNumber,
    messageEN: errorStringsEN.lockedConfirmationNumber,
  },
  IpRestriction: {
    type: 'general',
    message: errorStrings.ipRestriction,
    messageEN: errorStringsEN.ipRestriction,
  },
  CantCompleteTrx: {
    type: 'general',
    message: errorStrings.cantCompleteTrx,
    messageEN: errorStringsEN.cantCompleteTrx,
  },
  loginAccountUninitializedSMBC: {
    type: 'general',
    message: errorStrings.accountUninitializedSMBC,
    messageEN: errorStringsEN.accountUninitializedSMBC,
  },
  AccountUninitializedSMBC: {
    type: 'general',
    message: errorStrings.accountUninitializedSMBC,
    messageEN: errorStringsEN.accountUninitializedSMBC,
  },
  TimeoutOtpPayPay: {
    type: 'general',
    message: errorStrings.timeoutOtpPayPay,
    messageEN: errorStringsEN.timeoutOtpPayPay,
  },
  loginTimeoutOtpPayPay: {
    type: 'general',
    message: errorStrings.timeoutOtpPayPay,
    messageEN: errorStringsEN.timeoutOtpPayPay,
  },
  TimeoutOtpMufg: {
    type: 'general',
    message: errorStrings.timeoutOtpMufg,
    messageEN: errorStringsEN.timeoutOtpMufg,
  },
  TimeoutEmailCodeGMO: {
    type: 'general',
    message: errorStrings.timeoutEmailCodeGMO,
    messageEN: errorStringsEN.timeoutEmailCodeGMO,
  },
  TimeoutAppAuthGMO: {
    type: 'general',
    message: errorStrings.timeoutAppAuthGMO,
    messageEN: errorStringsEN.timeoutAppAuthGMO,
  },
  AnswersLimitRakuten: {
    type: 'general',
    message: errorStrings.answersLimitRakuten,
    messageEN: errorStringsEN.answersLimitRakuten,
  },
  AnswersLimitGMO: {
    type: 'general',
    message: errorStrings.answersLimitGMO,
    messageEN: errorStringsEN.answersLimitGMO,
  },
  IncorrectOtpLimitJapanPost: {
    type: 'general',
    message: errorStrings.incorrectOtpLimitJapanPost,
    messageEN: errorStringsEN.incorrectOtpLimitJapanPost,
  },
  loginAccountUninitializedMUFG: {
    type: 'general',
    message: errorStrings.accountUninitializedMUFG,
    messageEN: errorStringsEN.accountUninitializedMUFG,
  },
  OtpInactiveMUFG: {
    type: 'general',
    message: errorStrings.otpInactiveMUFG,
    messageEN: errorStringsEN.otpInactiveMUFG,
  },
  ServiceUnavailableMufg: {
    type: 'general',
    message: errorStrings.serviceUnavailableMufg,
    messageEN: errorStringsEN.serviceUnavailableMufg,
  },
  loginServiceUnavailableMufg: {
    type: 'general',
    message: errorStrings.serviceUnavailableMufg,
    messageEN: errorStringsEN.serviceUnavailableMufg,
  },
  ipRestriction: {
    type: 'general',
    message: errorStrings.ipRestriction,
    messageEN: errorStringsEN.ipRestriction,
  },
  appAuthRetry: {
    type: 'verification',
    message: errorStrings.appAuthRetry,
    messageEN: errorStringsEN.appAuthRetry,
  },
  CheckAccountPage: {
    type: 'general',
    message: errorStrings.goToBankSite,
    messageEN: errorStringsEN.goToBankSite,
  },
  loginAccountUninitializedRakuten: {
    type: 'general',
    message: errorStrings.loginAccountUninitializedRakuten,
    messageEN: errorStringsEN.loginAccountUninitializedRakuten,
  },
  AccountUninitializedRakuten: {
    type: 'general',
    message: errorStrings.loginAccountUninitializedRakuten,
    messageEN: errorStringsEN.loginAccountUninitializedRakuten,
  },
  TryLaterRakuten: {
    type: 'general',
    message: errorStrings.tryLaterRakuten,
    messageEN: errorStringsEN.tryLaterRakuten,
  },
  loginTryLaterRakuten: {
    type: 'general',
    message: errorStrings.tryLaterRakuten,
    messageEN: errorStringsEN.tryLaterRakuten,
  },
  loginAttemptsExceededRakuten: {
    type: 'general',
    message: errorStrings.loginAttemptsExceededRakuten,
    messageEN: errorStringsEN.loginAttemptsExceededRakuten,
  },
  TransfersDisabledResona: {
    type: 'general',
    message: errorStrings.transfersDisabledResona,
    messageEN: errorStringsEN.transfersDisabledResona,
  },
  loginUnavailableResona: {
    type: 'general',
    message: errorStrings.loginUnavailableResona,
    messageEN: errorStringsEN.loginUnavailableResona,
  },
  TransferPasswordError: {
    type: 'general',
    message: errorStrings.transferPasswordError,
    messageEN: errorStringsEN.transferPasswordError,
  },
  TransferCanceled: {
    type: 'general',
    message: errorStrings.transferCanceled,
    messageEN: errorStringsEN.transferCanceled,
  },
  loginAuthNotProvidedSbi: {
    type: 'general',
    message: errorStrings.loginAuthNotProvidedSbi,
    messageEN: errorStringsEN.loginAuthNotProvidedSbi,
  },
  CanceledByUserGMO: {
    type: 'general',
    message: errorStrings.canceledByUserGMO,
    messageEN: errorStringsEN.canceledByUserGMO,
  },
  loginMaintenancePayPay: {
    type: 'general',
    message: errorStrings.loginMaintenancePayPay,
    messageEN: errorStringsEN.loginMaintenancePayPay,
  },
  NoQrCodeConfirmation: {
    type: 'general',
    message: errorStrings.noQrCodeConfirmation,
    messageEN: errorStringsEN.noQrCodeConfirmation,
  },
  TransferUnavailableJapanPost: {
    type: 'general',
    message: errorStrings.transferUnavailableJapanPost,
    messageEN: errorStringsEN.transferUnavailableJapanPost,
  },
  loginServerCrowdedRakuten: {
    type: 'general',
    message: errorStrings.loginServerCrowdedRakuten,
    messageEN: errorStringsEN.loginServerCrowdedRakuten,
  },
  NightErrorRakuten: {
    type: 'general',
    message: errorStrings.nightErrorRakuten,
    messageEN: errorStringsEN.nightErrorRakuten,
  },
  loginMaintenanceResona: {
    type: 'general',
    message: errorStrings.loginMaintenanceResona,
    messageEN: errorStringsEN.loginMaintenanceResona,
  },
  AppNotActivatedJapanPost: {
    type: 'general',
    message: errorStrings.appNotActivatedJapanPost,
    messageEN: errorStringsEN.appNotActivatedJapanPost,
  },
  loginMaintenanceJapanPost: {
    type: 'general',
    message: errorStrings.maintenanceJapanPost,
    messageEN: errorStringsEN.maintenanceJapanPost,
  },
  MaintenanceJapanPost: {
    type: 'general',
    message: errorStrings.maintenanceJapanPost,
    messageEN: errorStringsEN.maintenanceJapanPost,
  },
  telephoneRetry: {
    type: 'verification',
    message: errorStrings.invalidPhoneNumber,
    messageEN: errorStringsEN.invalidPhoneNumber,
  },
  DailyLimitZero: {
    type: 'general',
    message: errorStrings.dailyLimitZero,
    messageEN: errorStringsEN.dailyLimitZero,
  },
  TransfersDisabledSaitamaResona: {
    type: 'general',
    message: errorStrings.transfersDisabledSaitamaResona,
    messageEN: errorStringsEN.transfersDisabledSaitamaResona,
  },
  TransferUnavailableResona: {
    type: 'general',
    message: errorStrings.transferUnavailableResona,
    messageEN: errorStringsEN.transferUnavailableResona,
  },
  AuthentictionNoMoreAvailableSaitama: {
    type: 'general',
    message: errorStrings.authentictionNoMoreAvailableSaitama,
    messageEN: errorStringsEN.authentictionNoMoreAvailableSaitama,
  },
  loginRegisterNewCreditCardSeven: {
    type: 'general',
    message: errorStrings.registerNewCreditCardSeven,
    messageEN: errorStringsEN.registerNewCreditCardSeven,
  },
  OtherSideMaintenancePayPay: {
    type: 'general',
    message: errorStrings.otherSideMaintenancePayPay,
    messageEN: errorStringsEN.otherSideMaintenancePayPay,
  },
  AutomaticLogoutMizuho: {
    type: 'general',
    message: errorStrings.automaticLogoutMizuho,
    messageEN: errorStringsEN.automaticLogoutMizuho,
  },
  IncorrectRecipientNameMizuho: {
    type: 'general',
    message: errorStrings.incorrectRecipientNameMizuho,
    messageEN: errorStringsEN.incorrectRecipientNameMizuho,
  },
  NewPasswordNeededRakuten: {
    type: 'general',
    message: errorStrings.newPasswordNeededRakuten,
    messageEN: errorStringsEN.newPasswordNeededRakuten,
  },
  QrCommunicationError: {
    type: 'general',
    message: errorStrings.qrCommunicationError,
    messageEN: errorStringsEN.qrCommunicationError,
  },
  TransactionSuspendedJapanPost: {
    type: 'general',
    message: errorStrings.transactionSuspended,
    messageEN: errorStringsEN.transactionSuspended,
  },
  DailyCumulativeLimitExceededJapanPost: {
    type: 'general',
    message: errorStrings.dailyCumulativeLimitExceededJapanPost,
    messageEN: errorStringsEN.dailyCumulativeLimitExceededJapanPost,
  },
  NotWithdrawalAccount: {
    type: 'general',
    message: errorStrings.notWithdrawalAccount,
    messageEN: errorStringsEN.notWithdrawalAccount,
  },
  AppCanceledResona: {
    type: 'general',
    message: errorStrings.appCanceledResona,
    messageEN: errorStringsEN.appCanceledResona,
  },
  AppCanceledSaitamaResona: {
    type: 'general',
    message: errorStrings.appCanceledSaitamaResona,
    messageEN: errorStringsEN.appCanceledSaitamaResona,
  },
  loginmaintenanceMufg: {
    type: 'general',
    message: errorStrings.loginmaintenanceMufg,
    messageEN: errorStringsEN.loginmaintenanceMufg,
  },
  EmailCodeExpiredMufg: {
    type: 'general',
    message: errorStrings.emailCodeExpiredMufg,
    messageEN: errorStringsEN.emailCodeExpiredMufg,
  },
  SecretUninitializedRakuten: {
    type: 'general',
    message: errorStrings.secretUninitializedRakuten,
    messageEN: errorStringsEN.secretUninitializedRakuten,
  },
  MaintenanceSbi: {
    type: 'general',
    message: errorStrings.maintenanceSbi,
    messageEN: errorStringsEN.maintenanceSbi,
  },
  DailyLimitResona: {
    type: 'general',
    message: errorStrings.dailyLimitResona,
    messageEN: errorStringsEN.dailyLimitResona,
  },
  dailyLimitSaitamaResona: {
    type: 'general',
    message: errorStrings.dailyLimitSaitamaResona,
    messageEN: errorStringsEN.dailyLimitSaitamaResona,
  },
  WithdrawalAccountUnavailableSMBC: {
    type: 'general',
    message: errorStrings.withdrawalAccountUnavailableSMBC,
    messageEN: errorStringsEN.withdrawalAccountUnavailableSMBC,
  },
  loginMaintenanceAujibun: {
    type: 'general',
    message: errorStrings.maintenanceAujibun,
    messageEN: errorStringsEN.maintenanceAujibun,
  },
  AppAuthNotSetSaitamaResona: {
    type: 'general',
    message: errorStrings.appAuthNotSetSaitamaResona,
    messageEN: errorStringsEN.appAuthNotSetSaitamaResona,
  },
  AppAuthNotSetResona: {
    type: 'general',
    message: errorStrings.appAuthNotSetResona,
    messageEN: errorStringsEN.appAuthNotSetResona,
  },
  TokenExpired: {
    type: 'general',
    message: errorStrings.tokenExpired,
    messageEN: errorStringsEN.tokenExpired,
  },
  TransferLimitPerDayAeon: {
    type: 'general',
    message: errorStrings.transferLimitPerDayAeon,
    messageEN: errorStringsEN.transferLimitPerDayAeon,
  },
  TransferLimitPerTransactionAeon: {
    type: 'general',
    message: errorStrings.transferLimitPerTransactionAeon,
    messageEN: errorStringsEN.transferLimitPerTransactionAeon,
  },
  loginAccountBlockedAeon: {
    type: 'general',
    message: errorStrings.loginAccountBlocked,
    messageEN: errorStringsEN.loginAccountBlocked,
  },
  loginMaintenanceAeon: {
    type: 'general',
    message: errorStrings.loginMaintenanceAeon,
    messageEN: errorStringsEN.loginMaintenanceAeon,
  },
  RegisterOneTimePasswordResona: {
    type: 'general',
    message: errorStrings.registerOneTimePasswordResona,
    messageEN: errorStringsEN.registerOneTimePasswordResona,
  },
  RegisterOneTimePasswordSaitamaResona: {
    type: 'general',
    message: errorStrings.registerOneTimePasswordSaitamaResona,
    messageEN: errorStringsEN.registerOneTimePasswordSaitamaResona,
  },
  ConfirmationPassLockedResona: {
    type: 'general',
    message: errorStrings.confirmationPassLockedResona,
    messageEN: errorStringsEN.confirmationPassLockedResona,
  },
  ConfirmationPassLockedSaitamaResona: {
    type: 'general',
    message: errorStrings.confirmationPassLockedSaitamaResona,
    messageEN: errorStringsEN.confirmationPassLockedSaitamaResona,
  },
  TrxInProgressFukuoka: {
    type: 'general',
    message: errorStrings.trxInProgressFukuoka,
    messageEN: errorStringsEN.trxInProgressFukuoka,
  },
  TransferLimitPerDaySony: {
    type: 'general',
    message: errorStrings.transferLimitPerDaySony,
    messageEN: errorStringsEN.transferLimitPerDaySony,
  },
  TransactionErrorSony: {
    type: 'general',
    message: errorStrings.transactionErrorSony,
    messageEN: errorStringsEN.transactionErrorSony,
  },
  TransferRejectedSony: {
    type: 'general',
    message: errorStrings.transferRejectedSony,
    messageEN: errorStringsEN.transferRejectedSony,
  },
  SystemErrorSBI: {
    type: 'general',
    message: errorStrings.systemErrorSBI,
    messageEN: errorStringsEN.systemErrorSBI,
  },
  UninitializedOtpFuku: {
    type: 'general',
    message: errorStrings.uninitializedOtpFuku,
    messageEN: errorStringsEN.uninitializedOtpFuku,
  },
  AlreadyLoggedInSuruga: {
    type: 'general',
    message: errorStrings.alreadyLoggedInSuruga,
    messageEN: errorStringsEN.alreadyLoggedInSuruga,
  },
  DailyLimitSuruga: {
    type: 'general',
    message: errorStrings.dailyLimitSuruga,
    messageEN: errorStringsEN.dailyLimitSuruga,
  },
  InvalidOperationFukuoka: {
    type: 'general',
    message: errorStrings.invalidOperationFukuoka,
    messageEN: errorStringsEN.invalidOperationFukuoka,
  },
  loginAccessErrorSbi: {
    type: 'general',
    message: errorStrings.loginAccessErrorSbi,
    messageEN: errorStringsEN.loginAccessErrorSbi,
  },
  TransferFeeNoMoneyRakuten: {
    type: 'general',
    message: errorStrings.transferFeeNoMoneyRakuten,
    messageEN: errorStringsEN.transferFeeNoMoneyRakuten,
  },
  ServiceUnavailableResona: {
    type: 'general',
    message: errorStrings.serviceUnavailableResona,
    messageEN: errorStringsEN.serviceUnavailableResona,
  },
  ServiceUnavailableSaitamaResona: {
    type: 'general',
    message: errorStrings.serviceUnavailableSaitamaResona,
    messageEN: errorStringsEN.serviceUnavailableSaitamaResona,
  },
  NotSupportedAuthenticationMethodAeon: {
    type: 'general',
    message: errorStrings.notSupportedAuthenticationMethodAeon,
    messageEN: errorStringsEN.notSupportedAuthenticationMethodAeon,
  },
  MaintenanceAujibun: {
    type: 'general',
    message: errorStrings.maintenanceAujibun,
    messageEN: errorStringsEN.maintenanceAujibun,
  },
  loginPasswordLockedAujibun: {
    type: 'general',
    message: errorStrings.passwordLockedAujibun,
    messageEN: errorStringsEN.passwordLockedAujibun,
  },
  NotApprovedTransactionsSaiatamaResona: {
    type: 'general',
    message: errorStrings.notApporvedTransactions,
    messageEN: errorStringsEN.notApporvedTransactions,
  },
  NotApprovedTransactionsResona: {
    type: 'general',
    message: errorStrings.notApporvedTransactions,
    messageEN: errorStringsEN.notApporvedTransactions,
  },
  loginUninitializedTokenJapanPost: {
    type: 'general',
    message: errorStrings.uninitializedTokenJapanPost,
    messageEN: errorStringsEN.uninitializedTokenJapanPost,
  },
  loginAccountBlockedSony: {
    type: 'general',
    message: errorStrings.accountBlockedSony,
    messageEN: errorStringsEN.accountBlockedSony,
  },
  loginServiceUnavailableGMO: {
    type: 'general',
    message: errorStrings.serviceUnavailableGMO,
    messageEN: errorStringsEN.serviceUnavailableGMO,
  },
  ServiceUnavailableGMO: {
    type: 'general',
    message: errorStrings.serviceUnavailableGMO,
    messageEN: errorStringsEN.serviceUnavailableGMO,
  },
  loginMaintenanceFukuoka: {
    type: 'general',
    message: errorStrings.loginMaintenanceFukuoka,
    messageEN: errorStringsEN.loginMaintenanceFukuoka,
  },
  loginEmailAddressRegistrationJapanPost: {
    type: 'general',
    message: errorStrings.emailAddressRegistrationJapanPost,
    messageEN: errorStringsEN.emailAddressRegistrationJapanPost,
  },
  loginTransactionPurposeDeclarationJapanPost: {
    type: 'general',
    message: errorStrings.transactionPurposeDeclarationJapanPost,
    messageEN: errorStringsEN.transactionPurposeDeclarationJapanPost,
  },
  loginMaintenanceSuruga: {
    type: 'general',
    message: errorStrings.maintenanceSuruga,
    messageEN: errorStringsEN.maintenanceSuruga,
  },
  TooManyRecordedTrxResona: {
    type: 'general',
    message: errorStrings.tooManyRecordedTrxResona,
    messageEN: errorStringsEN.tooManyRecordedTrxResona,
  },
  TransferCanceledFukuoka: {
    type: 'general',
    message: errorStrings.transferCanceledFukuoka,
    messageEN: errorStringsEN.transferCanceledFukuoka,
  },
  ApplicationRequiredGMO: {
    type: 'general',
    message: errorStrings.applicationRequiredGMO,
    messageEN: errorStringsEN.applicationRequiredGMO,
  },
  TransferCurrentlyUnavailableJapanPost: {
    type: 'general',
    message: errorStrings.transferCurrentlyUnavailableJapanPost,
    messageEN: errorStringsEN.transferCurrentlyUnavailableJapanPost,
  },
  TransferCurrentlyUnavailableSuruga: {
    type: 'general',
    message: errorStrings.transferCurrentlyUnavailableSuruga,
    messageEN: errorStringsEN.transferCurrentlyUnavailableSuruga,
  },
  loginSystemErrorResona: {
    type: 'general',
    message: errorStrings.systemError,
    messageEN: errorStringsEN.systemError,
  },
  loginSystemErrorSaitamaResona: {
    type: 'general',
    message: errorStrings.systemError,
    messageEN: errorStringsEN.systemError,
  },
  TransferDisabledJapanPost: {
    type: 'general',
    message: errorStrings.transferDisabledJapanPost,
    messageEN: errorStringsEN.transferDisabledJapanPost,
  },
  AuthAppNotUsedYetJapanPost: {
    type: 'general',
    message: errorStrings.authAppNotUsedYetJapanPost,
    messageEN: errorStringsEN.authAppNotUsedYetJapanPost,
  },
  loginReissuePasswordAeon: {
    type: 'general',
    message: errorStrings.reissuePasswordAeon,
    messageEN: errorStringsEN.reissuePasswordAeon,
  },
  ReissuePasswordAeon: {
    type: 'general',
    message: errorStrings.reissuePasswordAeon,
    messageEN: errorStringsEN.reissuePasswordAeon,
  },
  loginUnableToDeliverEmailSeven: {
    type: 'general',
    message: errorStrings.unableToDeliverEmailSeven,
    messageEN: errorStringsEN.unableToDeliverEmailSeven,
  },
  AuthAppLockedSeven: {
    type: 'general',
    message: errorStrings.authAppLockedSeven,
    messageEN: errorStringsEN.authAppLockedSeven,
  },
  AuthAppSuspendedSeven: {
    type: 'general',
    message: errorStrings.authAppSuspendedSeven,
    messageEN: errorStringsEN.authAppSuspendedSeven,
  },
  LoggedOutSeven: {
    type: 'general',
    message: errorStrings.loggedOutSeven,
    messageEN: errorStringsEN.loggedOutSeven,
  },
  OneTimeKeyInvalidStateRakuten: {
    type: 'general',
    message: errorStrings.oneTimeKeyInvalidStateRakuten,
    messageEN: errorStringsEN.oneTimeKeyInvalidStateRakuten,
  },
  loginBankIsBusySmbc: {
    type: 'general',
    message: errorStrings.bankIsBusySmbc,
    messageEN: errorStringsEN.bankIsBusySmbc,
  },
  BankIsBusySmbc: {
    type: 'general',
    message: errorStrings.bankIsBusySmbc,
    messageEN: errorStringsEN.bankIsBusySmbc,
  },
  TransferRestrictedRakuten: {
    type: 'general',
    message: errorStrings.transferRestrictedRakuten,
    messageEN: errorStringsEN.transferRestrictedRakuten,
  },
  SuspendedAccountMizuho: {
    type: 'general',
    message: errorStrings.suspendedAccountMizuho,
    messageEN: errorStringsEN.suspendedAccountMizuho,
  },
  BlockedAccountAeon: {
    type: 'general',
    message: errorStrings.blockedAccountAeon,
    messageEN: errorStringsEN.blockedAccountAeon,
  },
  ServiceHoursChangedSMBC: {
    type: 'general',
    message: errorStrings.serviceHoursChangedSMBC,
    messageEN: errorStringsEN.serviceHoursChangedSMBC,
  },
  BankTransferErrorAujibun: {
    type: 'general',
    message: errorStrings.bankTransferErrorAujibun,
    messageEN: errorStringsEN.bankTransferErrorAujibun,
  },
  loginPasswordNotRegisteredAujibun: {
    type: 'general',
    message: errorStrings.passwordNotRegisteredAujibun,
    messageEN: errorStringsEN.passwordNotRegisteredAujibun,
  },
  OneTimeTokenExpiredRakuten: {
    type: 'general',
    message: errorStrings.oneTimeTokenExpiredRakuten,
    messageEN: errorStringsEN.oneTimeTokenExpiredRakuten,
  },
  SameDayDeadlinePassedRakuten: {
    type: 'general',
    message: errorStrings.sameDayDeadlinePassedRakuten,
    messageEN: errorStringsEN.sameDayDeadlinePassedRakuten,
  },
  IllegalOperationRakuten: {
    type: 'general',
    message: errorStrings.illegalOperationRakuten,
    messageEN: errorStringsEN.illegalOperationRakuten,
  },
  AppAuthDisabledJapanPost: {
    type: 'general',
    message: errorStrings.appAuthDisabledJapanPost,
    messageEN: errorStringsEN.appAuthDisabledJapanPost,
  },
  IdentityNotVerifiedYetJapanPost: {
    type: 'general',
    message: errorStrings.identityNotVerifiedYetJapanPost,
    messageEN: errorStringsEN.identityNotVerifiedYetJapanPost,
  },
  RegisterSecretQuestionsResona: {
    type: 'general',
    message: errorStrings.registerSecretQuestionsResona,
    messageEN: errorStringsEN.registerSecretQuestionsResona,
  },
  RegisterSecretQuestionsSaitamaResona: {
    type: 'general',
    message: errorStrings.registerSecretQuestionsSaitamaResona,
    messageEN: errorStringsEN.registerSecretQuestionsSaitamaResona,
  },
  BankErrorMizuho: {
    type: 'general',
    message: errorStrings.bankErrorMizuho,
    messageEN: errorStringsEN.bankErrorMizuho,
  },
  NotUsedForCertainAmountOfTimeSeven: {
    type: 'general',
    message: errorStrings.notUsedForCertainAmountOfTimeSeven,
    messageEN: errorStringsEN.notUsedForCertainAmountOfTimeSeven,
  },
  TransferDisabledSony: {
    type: 'general',
    message: errorStrings.transferDisabledSony,
    messageEN: errorStringsEN.transferDisabledSony,
  },
  PinReissueRakuten: {
    type: 'general',
    message: errorStrings.pinReissueRakuten,
    messageEN: errorStringsEN.pinReissueRakuten,
  },
  DirectPasswordLockedMufg: {
    type: 'general',
    message: errorStrings.directPasswordLockedMufg,
    messageEN: errorStringsEN.directPasswordLockedMufg,
  },
  PageCurrentlyUnavailableRakuten: {
    type: 'general',
    message: errorStrings.pageCurrentlyUnavailableRakuten,
    messageEN: errorStringsEN.pageCurrentlyUnavailableRakuten,
  },
  VoiceCallVerificationFailedSMBC: {
    type: 'general',
    message: errorStrings.voiceCallVerificationFailedSMBC,
    messageEN: errorStringsEN.voiceCallVerificationFailedSMBC,
  },
  loginRegisterEmailSeven: {
    type: 'general',
    message: errorStrings.registerEmailSeven,
    messageEN: errorStringsEN.registerEmailSeven,
  },
  loginChangeLoginPasswordSeven: {
    type: 'general',
    message: errorStrings.changeLoginPasswordSeven,
    messageEN: errorStringsEN.changeLoginPasswordSeven,
  },
  loginCorporateCustomerBlockedGMO: {
    type: 'general',
    message: errorStrings.corporateCustomerBlockedGMO,
    messageEN: errorStringsEN.corporateCustomerBlockedGMO,
  },
  TransactionRestrictionPayPay: {
    type: 'general',
    message: errorStrings.transactionRestrictionPayPay,
    messageEN: errorStringsEN.transactionRestrictionPayPay,
  },
  LoggedOutAujibun: {
    type: 'general',
    message: errorStrings.loggedOutAujibun,
    messageEN: errorStringsEN.loggedOutAujibun,
  },
  TransactionNotPossibleSony: {
    type: 'general',
    message: errorStrings.transactionNotPossibleSony,
    messageEN: errorStringsEN.transactionNotPossibleSony,
  },
  loginMaintenanceRakuten: {
    type: 'general',
    message: errorStrings.loginMaintenanceRakuten,
    messageEN: errorStringsEN.loginMaintenanceRakuten,
  },
  EmailOtpDisabledJapanPost: {
    type: 'general',
    message: errorStrings.emailOtpDisabledJapanPost,
    messageEN: errorStringsEN.emailOtpDisabledJapanPost,
  },
  MaintenanceSmbc: {
    type: 'general',
    message: errorStrings.maintenanceSmbc,
    messageEN: errorStringsEN.maintenanceSmbc,
  },
  loginMaintenanceSmbc: {
    type: 'general',
    message: errorStrings.maintenanceSmbc,
    messageEN: errorStringsEN.maintenanceSmbc,
  },
  TransfersTemporaryRestrictedAujibun: {
    type: 'general',
    message: errorStrings.transfersTemporaryRestrictedAujibun,
    messageEN: errorStringsEN.transfersTemporaryRestrictedAujibun,
  },
  TransferCannotBeHandledMizuho: {
    type: 'general',
    message: errorStrings.transferCannotBeHandledMizuho,
    messageEN: errorStringsEN.transferCannotBeHandledMizuho,
  },
  loginNoPhoneRegisteredSmbc: {
    type: 'general',
    message: errorStrings.noPhoneRegisteredSmbc,
    messageEN: errorStringsEN.noPhoneRegisteredSmbc,
  },
  NoPhoneRegisteredSmbc: {
    type: 'general',
    message: errorStrings.noPhoneRegisteredSmbc,
    messageEN: errorStringsEN.noPhoneRegisteredSmbc,
  },
  TransactionNotOperatedCorrectlyAeon: {
    type: 'general',
    message: errorStrings.transactionNotOperatedCorrectlyAeon,
    messageEN: errorStringsEN.transactionNotOperatedCorrectlyAeon,
  },
  DestinationAccountErrorResona: {
    type: 'general',
    message: errorStrings.destinationAccountErrorResona,
    messageEN: errorStringsEN.destinationAccountErrorResona,
  },
  DestinationAccountErrorSaitamaResona: {
    type: 'general',
    message: errorStrings.destinationAccountErrorSaitamaResona,
    messageEN: errorStringsEN.destinationAccountErrorSaitamaResona,
  },
  DestinationAccountErrorFukuoka: {
    type: 'general',
    message: errorStrings.destinationAccountErrorFukuoka,
    messageEN: errorStringsEN.destinationAccountErrorFukuoka,
  },
  NotADepositableAccountRakuten: {
    type: 'general',
    message: errorStrings.notADepositableAccountRakuten,
    messageEN: errorStringsEN.notADepositableAccountRakuten,
  },
  InactiveTokenSMBC: {
    type: 'general',
    message: errorStrings.inactiveTokenSMBC,
    messageEN: errorStringsEN.inactiveTokenSMBC,
  },
  SemiRedirectLackingManualOption: {
    type: 'general',
    message: errorStrings.semiRedirectLackingManualOption,
    messageEN: errorStringsEN.semiRedirectLackingManualOption,
  },
  WrongRecipient: {
    type: 'general',
    message: errorStrings.wrongRecipient,
    messageEN: errorStringsEN.wrongRecipient,
  },
  UnsettledPendings: {
    type: 'global',
    message: errorStrings.unsettledPendings,
    messageEN: errorStringsEN.unsettledPendings,
  },
  PhoneVerificationErrorPayPay: {
    type: 'general',
    message: errorStrings.phoneVerificationErrorPayPay,
    messageEN: errorStringsEN.phoneVerificationErrorPayPay,
  },
  IncorrectAccountNumber: {
    type: 'general',
    message: errorStrings.incorrectAccountNumber,
    messageEN: errorStringsEN.incorrectAccountNumber,
  },
  AccessErrorRakuten: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  loginAccessErrorRakuten: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  loginCannotConfirmQrSMBC: {
    type: 'general',
    message: errorStrings.cannotConfirmQrSMBC,
    messageEN: errorStringsEN.cannotConfirmQrSMBC,
  },
  CannotConfirmQrSMBC: {
    type: 'general',
    message: errorStrings.cannotConfirmQrSMBC,
    messageEN: errorStringsEN.cannotConfirmQrSMBC,
  },
  UnableToConfirmOnDeviceSMBC: {
    type: 'general',
    message: errorStrings.unableToConfirmOnDeviceSMBC,
    messageEN: errorStringsEN.unableToConfirmOnDeviceSMBC,
  },
  loginUnableToConfirmOnDeviceSMBC: {
    type: 'general',
    message: errorStrings.unableToConfirmOnDeviceSMBC,
    messageEN: errorStringsEN.unableToConfirmOnDeviceSMBC,
  },
  CouldNotConfirmTransactionMufg: {
    type: 'general',
    message: errorStrings.couldNotConfirmTransactionMufg,
    messageEN: errorStringsEN.couldNotConfirmTransactionMufg,
  },
  InactiveSafetyPassOrOTP: {
    type: 'general',
    message: errorStrings.inactiveSafetyPassOrOTP,
    messageEN: errorStringsEN.inactiveSafetyPassOrOTP,
  },
  PreviousTransactionNotCompletedAeon: {
    type: 'general',
    message: errorStrings.previousTransactionNotCompletedAeon,
    messageEN: errorStringsEN.previousTransactionNotCompletedAeon,
  },
  ResetPasswordSuruga: {
    type: 'general',
    message: errorStrings.resetPasswordSuruga,
    messageEN: errorStringsEN.resetPasswordSuruga,
  },
  loginResetPasswordSuruga: {
    type: 'general',
    message: errorStrings.resetPasswordSuruga,
    messageEN: errorStringsEN.resetPasswordSuruga,
  },
  LoggedFromOtherDeviceSony: {
    type: 'general',
    message: errorStrings.loggedFromOtherDeviceSony,
    messageEN: errorStringsEN.loggedFromOtherDeviceSony,
  },
  MaintenanceSeven: {
    type: 'general',
    message: errorStrings.maintenanceSeven,
    messageEN: errorStringsEN.maintenanceSeven,
  },
  CustomerInfoConfirmation: {
    type: 'general',
    message: errorStrings.customerInfoConfirmation,
    messageEN: errorStringsEN.customerInfoConfirmation,
  },
  TransactionSuspendedPayPay: {
    type: 'general',
    message: errorStrings.transactionSuspendedPayPay,
    messageEN: errorStringsEN.transactionSuspendedPayPay,
  },
  loginTransactionSuspendedPayPay: {
    type: 'general',
    message: errorStrings.transactionSuspendedPayPay,
    messageEN: errorStringsEN.transactionSuspendedPayPay,
  },
  loginCustomerInfoConfirmation: {
    type: 'general',
    message: errorStrings.customerInfoConfirmation,
    messageEN: errorStringsEN.customerInfoConfirmation,
  },
  TransfersCurrentlyDisabledMizuho: {
    type: 'general',
    message: errorStrings.transfersCurrentlyDisabledMizuho,
    messageEN: errorStringsEN.transfersCurrentlyDisabledMizuho,
  },
  LoggedInFromAnotherBrowserResona: {
    type: 'general',
    message: errorStrings.loggedInFromAnotherBrowserResona,
    messageEN: errorStringsEN.loggedInFromAnotherBrowserResona,
  },
  SmartphoneAuthenticationSettingCanceledResona: {
    type: 'general',
    message: errorStrings.smartphoneAuthenticationSettingCanceledResona,
    messageEN: errorStringsEN.smartphoneAuthenticationSettingCanceledResona,
  },
  ChangePasswordResona: {
    type: 'general',
    message: errorStrings.changePasswordResona,
    messageEN: errorStringsEN.changePasswordResona,
  },
  loginChangePasswordResona: {
    type: 'general',
    message: errorStrings.changePasswordResona,
    messageEN: errorStringsEN.changePasswordResona,
  },
  ChangePasswordSaitamaResona: {
    type: 'general',
    message: errorStrings.changePasswordResona,
    messageEN: errorStringsEN.changePasswordResona,
  },
  loginChangePasswordSaitamaResona: {
    type: 'general',
    message: errorStrings.changePasswordResona,
    messageEN: errorStringsEN.changePasswordResona,
  },
  RegisterEmailResona: {
    type: 'general',
    message: errorStrings.registerEmailResona,
    messageEN: errorStringsEN.registerEmailResona,
  },
  loginRegisterEmailResona: {
    type: 'general',
    message: errorStrings.registerEmailResona,
    messageEN: errorStringsEN.registerEmailResona,
  },
  RegisterEmailSaitamaResona: {
    type: 'general',
    message: errorStrings.registerEmailResona,
    messageEN: errorStringsEN.registerEmailResona,
  },
  loginRegisterEmailSaitamaResona: {
    type: 'general',
    message: errorStrings.registerEmailResona,
    messageEN: errorStringsEN.registerEmailResona,
  },
  AuthenticationNotCompletedResona: {
    type: 'general',
    message: errorStrings.authenticationNotCompletedResona,
    messageEN: errorStringsEN.authenticationNotCompletedResona,
  },
  AuthenticationNotCompletedSaitamaResona: {
    type: 'general',
    message: errorStrings.authenticationNotCompletedSaitamaResona,
    messageEN: errorStringsEN.authenticationNotCompletedSaitamaResona,
  },
  TransactionsNotAcceptedTodayGMO: {
    type: 'general',
    message: errorStrings.transactionsNotAcceptedTodayGMO,
    messageEN: errorStringsEN.transactionsNotAcceptedTodayGMO,
  },
  SmartphoneAuthenticationRequiredResona: {
    type: 'general',
    message: errorStrings.smartphoneAuthenticationRequiredResona,
    messageEN: errorStringsEN.smartphoneAuthenticationRequiredResona,
  },
  SmartphoneAuthenticationRequiredSaitamaResona: {
    type: 'general',
    message: errorStrings.smartphoneAuthenticationRequiredSaitamaResona,
    messageEN: errorStringsEN.smartphoneAuthenticationRequiredSaitamaResona,
  },
  OldAuthenticationMethodUnavailableResona: {
    type: 'general',
    message: errorStrings.oldAuthenticationMethodUnavailableResona,
    messageEN: errorStringsEN.oldAuthenticationMethodUnavailableResona,
  },
  OldAuthenticationMethodUnavailableSaitamaResona: {
    type: 'general',
    message: errorStrings.oldAuthenticationMethodUnavailableSaitamaResona,
    messageEN: errorStringsEN.oldAuthenticationMethodUnavailableSaitamaResona,
  },
  AccountNotConfirmedMUFG: {
    type: 'general',
    message: errorStrings.accountNotConfirmedMUFG,
    messageEN: errorStringsEN.accountNotConfirmedMUFG,
  },
  CorporateAccountUnavailable: {
    type: 'general',
    message: errorStrings.corporateAccountUnavailable,
    messageEN: errorStringsEN.corporateAccountUnavailable,
  },
  DepositAccountNotRegisteredSMBC: {
    type: 'general',
    message: errorStrings.depositAccountNotRegisteredSMBC,
    messageEN: errorStringsEN.depositAccountNotRegisteredSMBC,
  },
  ParallelBankLoginAujibun: {
    type: 'general',
    message: errorStrings.parallelBankLoginAujibun,
    messageEN: errorStringsEN.parallelBankLoginAujibun,
  },
  CustomerUsingPaperCardMizuho: {
    type: 'general',
    message: errorStrings.customerUsingPaperCardMizuho,
    messageEN: errorStringsEN.customerUsingPaperCardMizuho,
  },
  OutsideOperatingHoursMUFG: {
    type: 'general',
    message: errorStrings.outsideOperatingHoursMUFG,
    messageEN: errorStringsEN.outsideOperatingHoursMUFG,
  },
  TransactionNotFoundInHistoryPayPay: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.transactionNotFoundInHistoryPayPay,
  },
  MaintenanceSony: {
    type: 'general',
    message: errorStrings.maintenanceSony,
    messageEN: errorStringsEN.maintenanceSony,
  },
  loginMaintenanceSony: {
    type: 'general',
    message: errorStrings.maintenanceSony,
    messageEN: errorStringsEN.maintenanceSony,
  },
  ThirdPartyDeposit: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  SemiError: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  LackOfRecipientAccountSEMI: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.internalError,
  },
  OtpSuspendedMUFG: {
    type: 'general',
    message: errorStrings.otpSuspendedMUFG,
    messageEN: errorStringsEN.otpSuspendedMUFG,
  },
  CustomerInformationVerificationAujibun: {
    type: 'general',
    message: errorStrings.CustomerInformationVerificationAujibun,
    messageEN: errorStringsEN.CustomerInformationVerificationAujibun,
  },
  FunctionNotAvailableForAccount: {
    type: 'general',
    message: errorStrings.functionNotAvailableForAccount,
    messageEN: errorStringsEN.functionNotAvailableForAccount,
  },
  MyGateRestrictionTransaction: {
    type: 'general',
    message: errorStrings.myGateRestrictionTransaction,
    messageEN: errorStringsEN.myGateRestrictionTransaction,
  },
  SystemErrorOccurred: {
    type: 'general',
    message: errorStrings.systemErrorOccurred,
    messageEN: errorStringsEN.systemErrorOccurred,
  },
  IncorrectBankInformationRakuten: {
    type: 'general',
    message: errorStrings.incorrectBankInformationRakuten,
    messageEN: errorStringsEN.incorrectBankInformationRakuten,
  },
  InvalidRecipientNameFormat: {
    type: 'general',
    message: errorStrings.internalError,
    messageEN: errorStringsEN.invalidRecipientNameFormat,
  },
  loginOTPAppRegistrationNotCompleted: {
    type: 'general',
    message: errorStrings.loginOTPAppRegistrationNotCompleted,
    messageEN: errorStringsEN.loginOTPAppRegistrationNotCompleted,
  },
  UpperLimitExceededMUFG: {
    type: 'general',
    message: errorStrings.upperLimitExceededMUFG,
    messageEN: errorStringsEN.upperLimitExceededMUFG,
  },
  TemporaryPinPayPay: {
    type: 'general',
    message: errorStrings.temporaryPinPayPay,
    messageEN: errorStringsEN.temporaryPinPayPay,
  },
  loginTemporaryPinPayPay: {
    type: 'general',
    message: errorStrings.temporaryPinPayPay,
    messageEN: errorStringsEN.temporaryPinPayPay,
  },
  CannotContinueTransactionRakuten: {
    type: 'general',
    message: errorStrings.cannotContinueTransactionRakuten,
    messageEN: errorStringsEN.cannotContinueTransactionRakuten,
  },
  OperationDidNotCompleteSuccessfullySMBC: {
    type: 'general',
    message: errorStrings.operationDidNotCompleteSuccessfullySMBC,
    messageEN: errorStringsEN.operationDidNotCompleteSuccessfullySMBC,
  },
  loginOperationDidNotCompleteSuccessfullySMBC: {
    type: 'general',
    message: errorStrings.operationDidNotCompleteSuccessfullySMBC,
    messageEN: errorStringsEN.operationDidNotCompleteSuccessfullySMBC,
  },
  RequestFromExpiredPageSMBC: {
    type: 'general',
    message: errorStrings.cantCompleteTrx,
    messageEN: errorStringsEN.requestFromExpiredPageSMBC,
  },
};

const errorOverwrite = {
  PhoneVerificationErrorPayPay: 'PH-VerificationErrorPayPay',
};

const hiddenErrors = {
  merchant: [
    { errorCode: 'LackOfRecipientAccount', overWriteError: 'Unknown' },
    { errorCode: 'LackOfRecipientAccountSEMI', overWriteError: 'Unknown' },
    { errorCode: 'ThirdPartyDeposit', overWriteError: 'Timeout3' },
  ],
};

export { errors, errorStrings, errorOverwrite, hiddenErrors };
